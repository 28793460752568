import { isEqual } from 'lodash';
import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const POLLING_INTERVAL = 60000;
// Increment/Change this value to clear session storage
const SESSION_STORAGE_VERSION = '2';

export const useVersionCheck = () => {
  const router = useRouter();
  const reloadRequired = ref(false);

  router.afterEach((to, from) => {
    // This is to avoid calling refresh when the query params change
    const queryChanged = !isEqual(to.query, from.query);
    if (reloadRequired.value && !queryChanged) {
      reloadRequired.value = false;
      // Force reload to get new index.html version
      window.location.reload();
    }
  });

  let interval: number;
  let localVersion: string;
  const checkAppVersion = () => {
    localVersion = localStorage.getItem('version') ?? '';
    interval = setInterval(() => {
      fetch('/version.txt')
        .then((res) => {
          if (res.ok) return res.text();
          return Promise.reject(res);
        })
        .then((version) => {
          if (version !== localVersion) {
            localStorage.setItem('version', version);
            localVersion = version;
            reloadRequired.value = true;
          }
        })
        .catch(() => {
          // If version doesn't exist do nothing
        });
    }, POLLING_INTERVAL);
  };

  let localSessionStorageVersion: string;
  const checkSessionStorageVersion = () => {
    localSessionStorageVersion = localStorage.getItem('sessionVersion') ?? '';
    if (localSessionStorageVersion !== SESSION_STORAGE_VERSION) {
      sessionStorage.clear();
      localStorage.setItem('sessionVersion', SESSION_STORAGE_VERSION);
    }
  };

  onMounted(() => {
    checkSessionStorageVersion();
    checkAppVersion();
  });

  onUnmounted(() => {
    clearInterval(interval);
  });
};
