import { Logger } from '@/shared/services/logger';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import {
  CompanyAutocompleteErrorType,
  Registration,
} from '@/pages/registration/interfaces/registration';
import { graphql } from '@/shared/graphql/gql';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { API_LINK } from '@/shared/constants/apiLinks';
import { NewCompanyForm } from '@/shared/interfaces/forms/NewCompanyForm';
import { ApiCompany } from '@/shared/interfaces/api/ApiCompany';
import { CompanyAutocompleteOption } from '@/shared/interfaces/others/CompanyAutocompleteOption';
import { ApiCompanyAutocomplete } from '@/shared/interfaces/api/ApiCompanyAutocomplete';
import {
  OTHER_ERROR,
  TOO_MANY_REQUEST_ERROR,
  TOO_MANY_REQUEST_STATUS_CODE,
} from '@/pages/registration/constants/RegistrationErrors';
import { DEFAULT_COUNTRY } from '@/shared/constants/country';
import { ApiRemoteCustomer } from '@/shared/interfaces/api/ApiRemoteCustomer';
import { ImportedCompanyRegistrationForm } from '@/shared/interfaces/forms/ImportedCompanyRegistrationForm';
import { ImportedCompanyData } from '@/shared/interfaces/forms/ImportedClientRegistrationForm';
import { RegistrationCompanyForm } from '@/shared/interfaces/forms/RegistrationCompanyForm';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { ResendRegistrationConfirmationEmailMutation } from '@/shared/graphql/graphql';

export class RegistrationService implements Registration {
  api: IApi;

  rootApi: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, rootApi: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.rootApi = rootApi;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async validateRegistration(fields: NewCompanyForm): ResultAsync<undefined, ViolationErrorMap> {
    // TODO country field should change
    const validationData = {
      ...fields,
      country: DEFAULT_COUNTRY,
    };
    const body = JSON.stringify(validationData);
    const response = await this.api.post(API_LINK.companyValidate, {
      body,
    });
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(undefined);
  }

  async registerCompany(fields: NewCompanyForm): ResultAsync<ApiCompany, ViolationErrorMap> {
    const registrationData = {
      ...fields,
      country: DEFAULT_COUNTRY,
    };
    const body = JSON.stringify(registrationData);

    const response = await this.api.post<ApiCompany>(API_LINK.companies(), { body });
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async registerCompanyV2(
    fields: RegistrationCompanyForm
  ): ResultAsync<ApiCompany, ViolationErrorMap> {
    const registrationData = {
      ...fields,
    };
    const body = JSON.stringify(registrationData);

    const response = await this.api.post<ApiCompany>(API_LINK.companiesV2(), { body });
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async getCompanyAddresses(
    query: string
  ): ResultAsync<CompanyAutocompleteOption[], CompanyAutocompleteErrorType> {
    const response = await this.rootApi.get<ApiCompanyAutocomplete[]>(
      API_LINK.autocompleteCompany(query)
    );
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { status } = response.error;
    if (status === TOO_MANY_REQUEST_STATUS_CODE) {
      return err({ error: TOO_MANY_REQUEST_ERROR });
    }
    return err({ error: OTHER_ERROR });
  }

  // probably outdated
  async registerImportedCompany(
    value: ImportedCompanyRegistrationForm
  ): ResultAsync<ApiRemoteCustomer, ViolationErrorMap> {
    const registrationData = {
      ...value,
      id: undefined,
      country: DEFAULT_COUNTRY,
    };
    const body = JSON.stringify(registrationData);

    const response = await this.api.post<ApiRemoteCustomer>(
      API_LINK.remoteCustomerCompanies(value.id),
      { body }
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async registerImportedClient(
    value: ImportedCompanyData
  ): ResultAsync<ApiRemoteCustomer, ViolationErrorMap> {
    const registrationData = {
      ...value,
      id: undefined,
      country: DEFAULT_COUNTRY,
    };
    const body = JSON.stringify(registrationData);

    const response = await this.api.put<ApiRemoteCustomer>(
      API_LINK.remoteCustomerCompaniesV2(value?.id || ''),
      { body }
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async inviteImportedClient(
    value: ImportedCompanyData
  ): ResultAsync<ApiRemoteCustomer, ViolationErrorMap> {
    const registrationData = {
      ...value,
      id: undefined,
      country: DEFAULT_COUNTRY,
    };
    const body = JSON.stringify(registrationData);

    const response = await this.api.put<ApiRemoteCustomer>(
      API_LINK.remoteCustomerInvite(value?.id || ''),
      { body }
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async resendInvitation(
    customerId: string
  ): ResultAsync<Record<string, unknown>, ViolationErrorMap> {
    const response = await this.api.post<Record<string, unknown>>(
      API_LINK.resendInvitation(customerId)
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async resendInvitationV1(
    companyId: string
  ): ResultAsync<Record<string, unknown>, ViolationErrorMap> {
    const response = await this.api.put<Record<string, unknown>>(
      API_LINK.resendInvitationV1(companyId)
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async resendRegistrationConfirmation(
    companyId: string
  ): ResultAsync<
    | ResendRegistrationConfirmationEmailMutation['resendRegistrationConfirmationEmail']
    | undefined
    | null,
    IGraphQLError
  > {
    const resendRegistrationConfirmationEmailMutation = graphql(`
      mutation ResendRegistrationConfirmationEmail(
        $input: ResendRegistrationConfirmationEmailInput!
      ) {
        resendRegistrationConfirmationEmail(input: $input) {
          id
        }
      }
    `);
    const response = await this.graphql.mutate(resendRegistrationConfirmationEmailMutation, {
      input: {
        companyId,
      },
    });
    if (response.isOk()) {
      return ok(response.value?.resendRegistrationConfirmationEmail);
    }
    return err(response.error);
  }
}
