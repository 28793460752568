import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

export const useDynamicTranslations = () => {
  const { setLocaleMessage, messages } = useI18n();
  onMounted(() => {
    const languages = ['de', 'en'];
    languages.forEach(async (lang) => {
      try {
        const response = await fetch(`/translations/${lang}.json`);
        const data = await response.json();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setLocaleMessage(lang, { ...(messages.value as any)[lang], ...data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`Failed to load dynamic ${lang} translations`, error);
      }
    });
  });
};
