import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi } from '@/shared/interfaces/api';
import { Categories } from '@/pages/productOverview/interfaces/categories';
import { ApiCategory } from '@/shared/interfaces/api/ApiCategory';
import { CollectionResource } from '@/shared/interfaces/pagination';
import { IGgraphQL } from '@/shared/interfaces/graphql';
import { Features } from '@/shared/interfaces/features';
import { graphql } from '@/shared/graphql/gql';
import { ChannelEnum, ProductCategory, ProductCategoryEnum } from '@/shared/graphql/graphql';

export class CategoriesService implements Categories {
  api: IApi;

  graphql: IGgraphQL;

  features: Features;

  logger: Logger;

  constructor(api: IApi, graphqlApi: IGgraphQL, features: Features, logger: Logger) {
    this.api = api;
    this.graphql = graphqlApi;
    this.features = features;
    this.logger = logger;
  }

  async getCategories(): Promise<ApiCategory[]> {
    if (this.features.isEnabled('fe_vm_120_graphql_categories')) {
      const getCategoriesQuery = graphql(`
        query getCategories($type: ProductCategoryEnum!) {
          productCategories(type: $type) {
            items {
              code
              id
              images {
                path
                type
              }
              name
            }
          }
        }
      `);
      const result = await this.graphql.query(getCategoriesQuery, {
        type: ProductCategoryEnum.Sales,
      });
      return result.isOk()
        ? result.value?.productCategories?.items?.map((item) =>
            this.mapProductCategoryToCategory(item as ProductCategory)
          ) ?? []
        : [];
    }
    const result = await this.api.get<CollectionResource<ApiCategory>>(API_LINK.categories);
    return result.isOk() ? result.value.data.items : [];
  }

  async getSubCategories(id: number | string): Promise<ApiCategory[]> {
    if (this.features.isEnabled('fe_vm_120_graphql_categories')) {
      const getSubCategoriesQuery = graphql(`
        query getSubCategories($channel: ChannelEnum!, $categoryId: Uuid!) {
          subCategories(channel: $channel, category_id: $categoryId) {
            items {
              code
              id
              images {
                path
                type
              }
              name
            }
          }
        }
      `);
      const result = await this.graphql.query(getSubCategoriesQuery, {
        categoryId: id.toString(),
        channel: ChannelEnum.Ep,
      });
      return result.isOk()
        ? result.value?.subCategories?.items?.map((item) =>
            this.mapProductCategoryToCategory(item as ProductCategory)
          ) ?? []
        : [];
    }
    const result = await this.api.get<CollectionResource<ApiCategory>>(
      API_LINK.subCategories(id.toString())
    );
    return result.isOk() ? result.value.data.items : [];
  }

  mapProductCategoryToCategory(category: ProductCategory): ApiCategory {
    return {
      id: category?.id ?? '',
      code: category?.code ?? '',
      name: category?.name ?? '',
      images: category?.images?.map((image) => ({
        path: image?.path ?? '',
        type: image?.type ?? '',
      })),
    };
  }
}
