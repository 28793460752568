import { Dates, DatesAddOptions } from '@/shared/interfaces/dates';

export class DatesService implements Dates {
  now(): Date {
    return new Date();
  }

  add(date: Date, options: DatesAddOptions): Date {
    const result = new Date(date);
    result.setDate(date.getDate() + (options.days ?? 0));
    return result;
  }
}
