<template>
  <div
    v-if="initializedOptimizely && !isUnderMaintenance"
    class="flex flex-col h-full"
    :class="{ missingFeature: features.hideMissingFeatures }"
  >
    <router-view />
  </div>
  <div v-if="initializedOptimizely && isUnderMaintenance" class="flex flex-col h-full">
    <MaintenancePage />
  </div>
  <BaseSpinner v-if="!initializedOptimizely" />
  <NotificationsWrapper />
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useFeatures } from '@/shared/composables/useFeatures';
import { useDynamicTranslations } from '@/shared/composables/useDynamicTranslations';
import NotificationsWrapper from '@/shared/components/molecules/NotificationCard/NotificationsWrapper.vue';
import BaseSpinner from '@/shared/components/atoms/visual/BaseSpinner/BaseSpinner.vue';
import { useAnalytics } from '@/shared/composables/useAnalytics';
import { useVersionCheck } from '@/shared/composables/useVersionCheck';
import { usePermissions } from '@/shared/composables/usePermissions';
import { injectStrict } from '@/shared/utils/injectStrict';
import { AuthKey } from '@/shared/constants/injectables';
import MaintenancePage from '@/pages/maintenance/MaintenancePage.vue';
import { useMaintenanceStore } from '@/shared/composables/global/useMaintenanceMode';

export default defineComponent({
  components: { MaintenancePage, NotificationsWrapper, BaseSpinner },
  setup() {
    const auth = injectStrict(AuthKey);
    const { initializedOptimizely, features, updateFeatures } = useFeatures();
    const { isUnderMaintenance, setIsUnderMaintenance } = useMaintenanceStore();
    const { updatePermissions } = usePermissions();
    useDynamicTranslations();
    useVersionCheck();
    const { init } = useAnalytics();
    init();

    const enableWidget = () => {
      const element = document.getElementById('jsd-widget');
      if (features.kkc_159_attlasian_feedback_widget && element) {
        element.style.display = 'block';
      }
    };

    watch(initializedOptimizely, () => {
      // This reads feature flags
      updatePermissions();
      enableWidget();
      setIsUnderMaintenance(!!features.fe_kkc_308_maintenance_mode);
    });

    auth.subscribe(() => {
      updateFeatures();
      updatePermissions();
      enableWidget();
    });

    return { initializedOptimizely, features, isUnderMaintenance };
  },
});
</script>
<style>
.missingFeature .missing-feature {
  @apply opacity-40 pointer-events-none;
}

#jsd-widget {
  display: none;
}
</style>
