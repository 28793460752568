import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomSelect = _resolveComponent("CustomSelect")!

  return (_openBlock(), _createBlock(_component_CustomSelect, {
    options: _ctx.options,
    caret: true,
    placeholder: _ctx.t('shared_select_placeholder'),
    class: _normalizeClass(['select-input', _ctx.SelectInputStyles[_ctx.variant]])
  }, null, 8, ["options", "placeholder", "class"]))
}