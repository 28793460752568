import { IApi } from '@/shared/interfaces/api';
import { ContactPersons } from '@/pages/clientContacts/interfaces/contacts';
import { Err, err, Ok, ok } from '@/shared/utils/result';
import { API_LINK } from '@/shared/constants/apiLinks';

export class ContactUniquenessService implements ContactPersons {
  api: IApi;

  constructor(api: IApi) {
    this.api = api;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async validate(companyId: string, contactPersonData: object): Promise<Ok<any> | Err<any>> {
    const body = JSON.stringify(contactPersonData);
    const response = await this.api.put(API_LINK.uniqueContactPerson(companyId), {
      body,
    });

    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }
}
