import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { router } from '@/root/router';

export const sentry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install: (app: any) => {
    Sentry.init({
      app,
      dsn: process.env.VUE_APP_SENTRY_ID,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            'localhost',
            'api.staging.employee.kurt-koenig.de',
            'auth.staging.employee.kurt-koenig.de',
            'api.employee.kurt-koenig.de',
            'auth.employee.kurt-koenig.de',
            /^\//,
          ],
        }),
      ],
      beforeSend(event) {
        return event;
      },
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_THRESHOLD || '1.0'),
    });
    return app;
  },
};
