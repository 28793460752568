import { Features } from '@/shared/interfaces/features';
import featureFile from '@/shared/services/features.json';

export class LocalFeatureFlags implements Features {
  private features: Record<string, { enabled: boolean | null }>;

  constructor() {
    this.features = featureFile;
  }

  // fallback for mocked feature flags TRUE
  isEnabled = (key: string, fallback = true): boolean => {
    return this.features[key]?.enabled ?? fallback;
  };

  getFeatures(fallback = true) {
    return Object.entries(this.features).reduce(
      (acc, [key, { enabled }]) => ({ ...acc, [key]: enabled ?? fallback }),
      {}
    );
  }
}
