import optimizelySdk, { Client, setLogger } from '@optimizely/optimizely-sdk';
import { Features } from '@/shared/interfaces/features';
import featureFile from '@/shared/services/features.json';
import { ROLES_KEY, USERNAME_KEY } from '@/shared/constants/storage';
import { IStorage } from '@/shared/services/storage';

// 30 minutes in milliseconds
const OPTIMIZELY_POLLING_RATE = 1800000;

export class FeatureFlags implements Features {
  storage: IStorage;

  private readonly features: Record<string, { enabled: boolean | null }>;

  public readonly optimizelyInst: Client;

  constructor(storage: IStorage) {
    this.storage = storage;
    this.features = featureFile;

    this.optimizelyInst = optimizelySdk.createInstance({
      sdkKey: process.env.VUE_APP_OPTIMIZELY_KEY,
      datafileOptions: {
        autoUpdate: true,
        updateInterval: OPTIMIZELY_POLLING_RATE,
        urlTemplate: process.env.VUE_APP_OPTIMIZELY_URL || undefined,
      },
    });
    setLogger(null);
  }

  isEnabled = (key: string): boolean => {
    return (
      this.features[key]?.enabled ??
      this.optimizelyInst.isFeatureEnabled(key, 'FE-EP', {
        username: this.storage.get(USERNAME_KEY),
        roles: this.storage.get(ROLES_KEY),
      })
    );
  };

  getFeatures = () => {
    return {
      ...this.optimizelyInst
        .getEnabledFeatures('FE-EP', {
          username: this.storage.get(USERNAME_KEY),
          roles: this.storage.get(ROLES_KEY),
        })
        .reduce((acc, feature) => ({ ...acc, [feature]: true }), {}),
      ...Object.entries(this.features).reduce(
        (acc, [key, { enabled }]) => ({ ...acc, [key]: enabled }),
        {}
      ),
    };
  };
}
