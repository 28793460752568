import mixpanel, { Persistence } from 'mixpanel-browser';
import { ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';
import { useCurrentUser } from '@/shared/composables/useCurrentUser';
import { ProductWithQuantity } from '@/shared/interfaces/others/ProductWithQuantity';
import { PRODUCT_TYPES } from '@/shared/interfaces/others/productType';
import { ApiOrder } from '@/shared/interfaces/api/ApiOrder';
import { AddressType, CheckoutState } from '@/pages/checkout/interfaces/Checkout';
import { PAYMENT_TYPE } from '@/pages/checkout/composables/usePaymentOptions';
import { DiscountGroupCodeType } from '@/shared/interfaces/api/ApiDiscountGroup';
import { ApiCompany, EshopStatusesType } from '@/shared/interfaces/api/ApiCompany';

export interface AnalyticsEvent {
  name: string;
  data?: Record<string, unknown>;
}

interface UseAnalyticsType {
  identify: () => void;
  reset: () => void;
  init: () => void;
  track: (name: string, data?: Record<string, unknown>) => void;
  mapProductsToAnalytics: (products: ProductWithQuantity[]) => Record<string, unknown>[];
  mapOrderToAnalytics: (order: ApiOrder, checkout: CheckoutState) => Record<string, unknown>;
  mapCompanyToAnalytics: (company: ApiCompany) => Record<string, unknown>;
}

export const useAnalytics = createSharedComposable((): UseAnalyticsType => {
  const currentUser = useCurrentUser();
  const token = process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN;
  const loaded = ref<boolean>(false);

  const identify = () => {
    if (currentUser.userId.value != null && loaded.value) {
      mixpanel.identify(currentUser.userId.value);
    }
  };

  const reset = () => {
    // Clears the association with current user
    mixpanel.reset();
  };

  const init = () => {
    const persistence: Persistence = 'localStorage';
    const config = {
      persistence,
      loaded: () => {
        loaded.value = true;
        mixpanel.register({ System: 'Employee Portal' });
        identify();
      },
    };
    if (token) {
      mixpanel.init(token, config);
    }
  };

  const track = (name: string, data?: Record<string, unknown>) => {
    if (loaded.value) {
      mixpanel.track(name, data);
    }
  };

  const paymentMapping: Record<string, string> = { [PAYMENT_TYPE.invoice]: 'Invoice' };
  const deliveryMapping: Record<AddressType, string> = {
    shipping_regular_active: 'Standard Delivery',
    shipping_takeaway: 'Click And Collect',
  };
  const accountDiscountMapping: Record<DiscountGroupCodeType | 'default', string | undefined> = {
    A: 'Platinum',
    B: 'Gold',
    C: 'Silver',
    default: undefined,
  };
  const accountStatusMapping: Record<EshopStatusesType, string> = {
    started: 'Pending',
    imported: 'Uninvited',
    invited: 'Invited',
    active: 'Active',
  };

  const mapProductsToAnalytics = (products: ProductWithQuantity[]): Record<string, unknown>[] => {
    const packageMapping = { [PRODUCT_TYPES.package]: 'Package', [PRODUCT_TYPES.pieces]: 'Unit' };
    return products.map((product) => ({
      'Tool Name': product.product.name,
      Quantity: product.quantity,
      Type: packageMapping[product.package.type],
      'Total Quantity': product.package.count * product.quantity,
    }));
  };

  const mapOrderToAnalytics = (
    order: ApiOrder,
    checkout: CheckoutState
  ): Record<string, unknown> => {
    const volume = Number(order.totals.overallDiscountPercentage);
    return {
      Items: mapProductsToAnalytics(checkout.products ?? []),
      'Total Amount': order.totals.totalAmount.amountInCents / 100,
      Currency: order.totals.totalAmount.currency,
      'Payment Type': paymentMapping[checkout.paymentOption],
      'Delivery Option': deliveryMapping[checkout.deliveryOption],
      'Account Discount Group':
        accountDiscountMapping[
          checkout.companyForm?.selectedCompany?.data.discountGroup?.code ?? 'default'
        ],
      'Volume Discount Applied': Number.isNaN(volume) ? undefined : `${Math.abs(volume)}%`,
    };
  };

  const mapCompanyToAnalytics = (company: ApiCompany): Record<string, unknown> => {
    return {
      'Shop Status': accountStatusMapping[company.eshopStatus],
      'Account Discount Group': accountDiscountMapping[company.discountGroup?.code ?? 'default'],
    };
  };

  return {
    identify,
    reset,
    init,
    track,
    mapProductsToAnalytics,
    mapOrderToAnalytics,
    mapCompanyToAnalytics,
  };
});
