<template>
  <div class="w-full max-w-[192rem] px-2 md:px-3.5 2xl:px-7.5 mx-auto">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseContainer',
});
</script>
