import { toErrorsObject } from '@/shared/utils/fieldErrors';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { ApiError, IApi, Response, ResponseError } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { GenericResponse } from '@/shared/interfaces/genericResponse';
import { Auth } from '@/shared/interfaces/auth';
import AdvancedAbortController from '@/shared/services/advancedAbortController';
import { useMaintenanceStore } from '@/shared/composables/global/useMaintenanceMode';

const NO_CONTENT_STATUS = 204;
const ERROR_CODE = 500;
const MAINTENANCE_MODE = 503;
const DEFAULT_PAGE_SIZE = 20;

interface Config {
  apiUrl: string;
  headers?: Record<string, string>;
  auth?: Auth;
}

export class Api implements IApi {
  config: Config;

  headers: Record<string, string>;

  logger: Logger;

  constructor(config: Config, logger: Logger) {
    this.config = config;
    this.logger = logger;
    this.headers = {
      'Content-Type': 'application/json',
      // TODO: add dynamic language when we will have language switcher
      'Accept-Language': 'de_DE',
      Accept: 'application/json',
    };
  }

  async fetch<T>(
    url: string,
    params: RequestInit = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): ResultAsync<Response<T>, ResponseError | any> {
    try {
      const { auth, apiUrl } = this.config;
      if (auth && !auth.isAuthenticated()) {
        await auth.callRefresh();
      }
      const response = await fetch(`${apiUrl}${url}`, {
        ...params,
        headers: {
          ...(this.config.headers ?? this.headers),
          ...(auth ? { Authorization: `Bearer ${auth?.getAuthToken()}` } : {}),
          ...(params.headers ?? {}),
        },
      });
      if (response.status === MAINTENANCE_MODE) {
        useMaintenanceStore().setIsUnderMaintenance(true);
      }
      if (response.ok && response.status !== NO_CONTENT_STATUS) {
        const data = (await response.json()) as T;
        return ok({ status: response.status, data });
      }
      if (response.ok && response.status === NO_CONTENT_STATUS) {
        return ok({ status: response.status, data: undefined as unknown as T });
      }
      const errors = (await response.json()) as ApiError;
      this.logger.error('Error fetching url', errors, {
        status: response?.status,
        response,
        url,
        params,
      });
      return err({
        status: response.status,
        data: errors?.violations ? toErrorsObject(errors?.violations) : errors,
      });
    } catch (error) {
      // Fetch only throws this on network errors
      return err({
        status: ERROR_CODE,
        data: {},
      });
    }
  }

  get<T>(
    url: string,
    params = {},
    useAbortController = false
  ): ResultAsync<Response<T>, ResponseError> {
    const signal = useAbortController ? AdvancedAbortController.getSignal(url) : null;
    return this.fetch(url, { ...params, method: 'GET', signal });
  }

  post<T>(
    url: string,
    params = {},
    useAbortController = false
  ): ResultAsync<Response<T>, ResponseError> {
    const signal = useAbortController ? AdvancedAbortController.getSignal(url) : null;
    return this.fetch(url, { ...params, method: 'POST', signal });
  }

  put<T>(url: string, params = {}): ResultAsync<Response<T>, ResponseError> {
    return this.fetch(url, { ...params, method: 'PUT' });
  }

  patch<T>(url: string, params = {}): ResultAsync<Response<T>, ResponseError> {
    return this.fetch(url, { ...params, method: 'PATCH' });
  }

  delete<T>(url: string, params = {}): ResultAsync<Response<T>, ResponseError> {
    return this.fetch(url, { ...params, method: 'DELETE' });
  }

  async getPaginatedResource<T>(
    apiLink: (params: URLSearchParams) => string,
    options: DataQueryParams,
    useAbortController = false
  ): ResultAsync<PaginatedResource<T>, ResponseError> {
    const limit = options.limit ?? DEFAULT_PAGE_SIZE;
    const params = new URLSearchParams({
      ...options,
      ...(options.search && { [options.searchKey ?? 'name']: options.search ?? '' }),
      page: options.page.toString(),
      limit: limit.toString(),
    });

    const response = await this.get<GenericResponse<T>>(apiLink(params), {}, useAbortController);

    const result: PaginatedResource<T> = {
      page: options.page,
      pageCount: 1,
      totalCount: 0,
      items: [],
    };

    if (response.isErr()) {
      return err(response.error);
    }

    const { data } = response.value;
    result.pageCount = data.meta?.last ?? 1;
    result.totalCount = data.meta?.totalItems ?? data.items?.length;
    // This is the inconsistency between the auth and core services
    result.items = data.items ?? data.data ?? [];

    return ok(result);
  }
}
