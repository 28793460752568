export const EnvironmentIndicatorStyles = {
  development: {
    class: 'bg-white text-g-1500',
    value: 'Development',
  },
  staging: {
    class: 'bg-orange-100 text-white',
    value: 'Staging',
  },
};
