import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex flex-col h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MaintenancePage = _resolveComponent("MaintenancePage")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_NotificationsWrapper = _resolveComponent("NotificationsWrapper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.initializedOptimizely && !_ctx.isUnderMaintenance)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex flex-col h-full", { missingFeature: _ctx.features.hideMissingFeatures }])
        }, [
          _createVNode(_component_router_view)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.initializedOptimizely && _ctx.isUnderMaintenance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MaintenancePage)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.initializedOptimizely)
      ? (_openBlock(), _createBlock(_component_BaseSpinner, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_NotificationsWrapper)
  ], 64))
}