<template>
  <div :class="$style.notificationsWrapper">
    <BaseNotification v-for="notif in notifications" :key="notif.key" :notification="notif" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNotifications } from '@/shared/composables/global/useNotifications';

export default defineComponent({
  name: 'NotificationsWrapper',
  setup() {
    const { notifications } = useNotifications();
    return { notifications };
  },
});
</script>

<style module>
.notificationsWrapper {
  @apply fixed right-3.5 2xl:right-7.5 top-10 2xl:top-12 z-[120] flex flex-col-reverse;
}
</style>
