import * as Sentry from '@sentry/vue';

const UNAUTHORIZED_STATUS_CODE = 401;
const VALIDATION_STATUS_CODE = 422;

export class Logger {
  error(message: string, error: unknown, args?: Record<string, unknown>): void {
    if ([UNAUTHORIZED_STATUS_CODE, VALIDATION_STATUS_CODE].includes(args?.status as number)) return;

    Sentry.captureException(error, { extra: { details: args, message } });
  }
}
