<template>
  <h1 class="text-40 font-light text-g-900">
    <slot></slot>
  </h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompanyHeading',
});
</script>
