<!--Only ~ tablet styles-->
<template>
  <div class="flex items-center text-orange-100 text-16">
    <BaseIcon icon="error" class="h-3.5 w-3.5 mr-1.5 text-orange-100 flex-shrink-0" />
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseError',
});
</script>
