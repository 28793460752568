<template>
  <div data-test="notificationBlock" :class="$style.notification" @click="handleClose">
    <BaseIcon :icon="icon" class="flex-none h-6 w-6" />
    <div class="ml-2.5" :class="{ 'flex items-center': notification.center }">
      <BaseText v-if="notification.title" variant="b1" class="font-bold block mb-0.5">
        {{ notification.title }}
      </BaseText>
      <BaseText v-if="notification.label" variant="b2" class="text-g-800">
        {{ notification.label }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useNotifications } from '@/shared/composables/global/useNotifications';
import { Notification } from '@/shared/interfaces/notifications';

const NOTIFICATION_EXPIRE_TIME = 5000;

const NOTIFICATION_TYPES: { [key: string]: string } = {
  error: 'error',
  info: 'info',
};

export default defineComponent({
  name: 'BaseNotification',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  setup(props) {
    const { removeNotification } = useNotifications();

    const handleClose = () => {
      removeNotification(props.notification.key || '');
    };

    const icon = computed(
      () => props.notification.icon ?? NOTIFICATION_TYPES[props.notification.type || '']
    );

    setTimeout(handleClose, NOTIFICATION_EXPIRE_TIME);

    return {
      handleClose,
      icon,
    };
  },
});
</script>

<style module>
@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.notification {
  animation: 0.1s slidein;
  width: 468px;
  @apply my-1.5 bg-g-1400 flex p-2.5 align-middle text-white rounded-5;
}
</style>
