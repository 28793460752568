<template>
  <div class="wrapper">
    <div class="spinner spinner-1" />
    <div class="spinner spinner-2" />
    <div class="spinner spinner-3" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseSpinner',
});
</script>

<style scoped>
.wrapper {
  @apply flex justify-center items-center;
  height: 100vh;
}

.spinner {
  @apply bg-orange-100;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  margin: 0 0.5rem;
}

.spinner-1 {
  animation: fade 1.1s ease-in-out 0.18s infinite both;
}

.spinner-2 {
  animation: fade 1.1s ease-in-out 0.36s infinite both;
}

.spinner-3 {
  animation: fade 1.1s ease-in-out 0.54s infinite both;
}

@keyframes fade {
  0% {
    opacity: 0.35;
    transform: scale(0.87);
  }
  37.5% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 0.35;
    transform: scale(0.87);
  }
  100% {
    opacity: 0.35;
    transform: scale(0.87);
  }
}
</style>
