export const USER_ID_KEY = 'userId';
export const COMPANY_ID_KEY = 'companyId';
export const FIRST_NAME_KEY = 'firstName';
export const LAST_NAME_KEY = 'lastName';
export const FULL_NAME_KEY = 'fullName';
export const COMPANY_KEY = 'company';

export const ROLES_KEY = 'roles';
export const USERNAME_KEY = 'username';
export const EMAIL_KEY = 'email';
