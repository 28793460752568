import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync, err } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentCart } from '@/shared/interfaces/rentCart';
import {
  RentOrderProduct,
  RentCartInput,
  Void,
  RentOrderProductInput,
} from '@/shared/graphql/graphql';

export class RentCartService implements RentCart {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getCart(): ResultAsync<RentOrderProduct[], IGraphQLError> {
    const getCartQuery = graphql(`
      query getCart {
        rentCart {
          orderProducts {
            id
            productId
            branch
            rent
            parentId
            serializedItemId
          }
        }
      }
    `);

    const response = await this.graphql.query(getCartQuery, {}, 'network-only');

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.rentCart?.orderProducts.map((item) => item) as RentOrderProduct[]);
  }

  async cartValidation(): ResultAsync<Void, IGraphQLError> {
    const cartValidationQuery = graphql(`
      query cartValidation {
        rentCartValidation {
          void
        }
      }
    `);

    const response = await this.graphql.query(cartValidationQuery, {}, 'network-only');

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }

  async updateCart(cart: RentCartInput): ResultAsync<Void, IGraphQLError> {
    const updateRentCartMutation = graphql(`
      mutation updateCart($input: RentCartInput!) {
        updateRentCart(input: $input) {
          void
        }
      }
    `);

    const response = await this.graphql.mutate(updateRentCartMutation, { input: cart });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }

  async updateCartProduct(product: RentOrderProductInput): ResultAsync<Void, IGraphQLError> {
    const updateRentCartProductMutation = graphql(`
      mutation updateCartProduct($input: RentOrderProductInput!) {
        updateRentCartProduct(input: $input) {
          void
        }
      }
    `);

    const response = await this.graphql.mutate(updateRentCartProductMutation, { input: product });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }

  async deleteCartProduct(id: string): ResultAsync<Void, IGraphQLError> {
    const deleteRentCartProductMutation = graphql(`
      mutation deleteRentCartProduct($input: UuidInput!) {
        deleteRentCartProduct(input: $input) {
          void
        }
      }
    `);

    const response = await this.graphql.mutate(deleteRentCartProductMutation, { input: { id } });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }
}
