import { reactive } from 'vue';
import { createSharedComposable } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { useFeatures } from '@/shared/composables/useFeatures';
import { useStorage } from '@/shared/composables/useStorage';
import { Permission, Permissions } from '@/shared/interfaces/permissions';
import { Role } from '@/shared/interfaces/roles';
import { roleMapping } from '@/shared/constants/roles';
import { injectStrict } from '@/shared/utils/injectStrict';
import { AuthKey } from '@/shared/constants/injectables';
import { routes } from '@/shared/constants/routes';

interface UsePermissionsType {
  permissions: Permissions;
  updatePermissions: () => void;
}

const initialValues = (value: boolean): Permissions => ({
  read_products: value,
  read_orders: value,
  read_customers: value,
  read_customer_orders: value,
  update_customer_information: value,
  read_customer_information: value,
  create_customer_communication: value,
  update_customer_communication: value,
  delete_customer_communication: value,
  read_customer_communication: value,
  update_customer_login: value,
  read_customer_login: value,
  create_customer_registration: value,
  update_customer_registration: value,
  update_customer_invitation: value,
  create_sales_order: value,
  create_user_roles: value,
  update_user_roles: value,
  delete_user_roles: value,
  read_user_roles: value,
  create_customer_roles: value,
  update_customer_roles: value,
  delete_customer_roles: value,
  read_customer_roles: value,
});

export const usePermissions = createSharedComposable((): UsePermissionsType => {
  const auth = injectStrict(AuthKey);
  const router = useRouter();

  const { features } = useFeatures();
  const rolesOverride = useStorage<string[] | undefined>('rolesOverride', []);

  const permissions = reactive<Permissions>(initialValues(false));

  const updatePermissions = () => {
    Object.assign(permissions, initialValues(false));
    const roles = auth.getRoles();
    // TODO: remove this after testing has been done
    if (features.fe_cs2_81_permissions_override_enabled) {
      roles.push(...(rolesOverride.value ?? []));
    }
    if (features.fe_cs2_81_permissions_enabled) {
      roles.forEach((role) => {
        roleMapping[role as Role]?.forEach((permission) => {
          permissions[permission] = true;
        });
      });
    } else {
      Object.assign(permissions, initialValues(true));
    }
  };

  router.beforeEach((to) => {
    if (!to.meta.permission || permissions[to.meta.permission as Permission]) {
      return true;
    }
    return routes.dashboard.path;
  });

  return { permissions, updatePermissions };
});
