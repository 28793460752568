import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_EnvironmentIndicator = _resolveComponent("EnvironmentIndicator")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_component_BaseContainer, {
      class: _normalizeClass(_ctx.$style.container)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_BaseImage, {
              icon: "kurtLogo",
              alt: "Kurt Konig logo",
              class: "hidden md:block h-[2.5rem] 2xl:h-[3.8rem] mb-1 2xl:mb-0"
            })
          ]),
          _createVNode(_component_EnvironmentIndicator)
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}