export type ProductTypeValues = 'EA' | 'XPK';
export type ProductType = 'pieces' | 'package';

export const PRODUCT_TYPES: Record<ProductType, ProductTypeValues> = {
  pieces: 'EA',
  package: 'XPK',
};

export interface PackageOption {
  type: ProductTypeValues;
  count: number;
}
