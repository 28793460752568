<template>
  <CustomSelect
    :options="options"
    :caret="true"
    :placeholder="t('shared_select_placeholder')"
    :class="['select-input', SelectInputStyles[variant]]"
  ></CustomSelect>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { Option } from '@/shared/interfaces/others/option';

const SelectInputStyles = {
  default: '',
  gray: 'dark-multiselect',
  lightGray: 'gray-multiselect',
};

export default defineComponent({
  name: 'SelectInput',
  props: {
    options: {
      type: Array as PropType<Option[]>,
      default: undefined,
    },
    variant: {
      type: String as PropType<keyof typeof SelectInputStyles>,
      default: 'default',
      validator: (value: string) => {
        return Object.keys(SelectInputStyles).includes(value);
      },
    },
  },
  setup() {
    const { t } = useTranslations();
    return { t, SelectInputStyles };
  },
});
</script>

<style>
.select-input .multiselect-option.is-selected {
  background-position: right 1rem center;
  background-size: 2rem 2rem;
  background-image: url('~@/shared/assets/ic_check_gray.svg');
  background-repeat: no-repeat;
}

.dark-multiselect.select-input .multiselect-option.is-selected {
  background-image: url('~@/shared/assets/ic_check_light.svg');
}

.dark-multiselect {
  @apply bg-g-1300 text-white hover:bg-g-1200;
}

.dark-multiselect.is-open {
  @apply bg-g-1200;
}

.dark-multiselect.multiselect.is-active {
  @apply ring-1 ring-g-1000;
}

.dark-multiselect.select-input .multiselect-caret {
  @apply bg-white;
}

.dark-multiselect .multiselect-placeholder {
  @apply text-white;
}

.dark-multiselect .multiselect-dropdown {
  bottom: -1px;
  left: -1px;
  right: -1px;
  @apply bg-g-1200;
}

.dark-multiselect .multiselect-dropdown.is-top {
  top: 0;
  bottom: auto;
}

.dark-multiselect .multiselect-option {
  @apply border-t border-g-1100;
}

.dark-multiselect .multiselect-options.is-top {
  @apply border-b-0;
}

.dark-multiselect .multiselect-option.is-selected,
.dark-multiselect .multiselect-option.is-pointed {
  @apply bg-g-1300 text-white;
}

.gray-multiselect {
  @apply bg-g-500 hover:bg-g-500;
}

.gray-multiselect.is-disabled {
  @apply bg-g-500 pointer-events-none;
}

.gray-multiselect .multiselect-option {
  @apply bg-g-400;
}

.gray-multiselect .multiselect-option {
  @apply border-t border-g-600;
}

.gray-multiselect .multiselect-option.is-selected,
.gray-multiselect .multiselect-option.is-pointed {
  @apply bg-g-500;
}
</style>
