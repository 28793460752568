import { Config } from '@/shared/interfaces/config';
import { DEFAULT_CURRENCY } from '@/shared/constants/currency';
import { DEFAULT_LOCALE } from '@/shared/constants/locale';

export const getConfig = (): Config => ({
  apiBaseUrl: process.env.VUE_APP_API_URL ?? '',
  apiUrl: process.env.VUE_APP_API_URL ? `${process.env.VUE_APP_API_URL}/api` : '',
  apiEmployeeUrl: process.env.VUE_APP_API_URL ? `${process.env.VUE_APP_API_URL}/api/employee` : '',
  apiGraphQLUrl: process.env.VUE_APP_API_URL ? `${process.env.VUE_APP_API_URL}/graphql/` : '',
  apiAuthUrl: process.env.VUE_APP_API_AUTH_URL ?? '',
  googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY ?? '',
  currency: DEFAULT_CURRENCY,
  locale: DEFAULT_LOCALE,
});
