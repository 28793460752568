import { Ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';
import {
  COMPANY_ID_KEY,
  COMPANY_KEY,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  FULL_NAME_KEY,
  LAST_NAME_KEY,
  USER_ID_KEY,
} from '@/shared/constants/storage';
import { useStorage } from '@/shared/composables/useStorage';
import { Employee } from '@/shared/interfaces/employee';
import { Address } from '@/shared/interfaces/address';

interface UseCurrentUserType {
  userId: Ref<string | null>;
  companyId: Ref<string | null>;
  firstName: Ref<string | null>;
  lastName: Ref<string | null>;
  fullName: Ref<string | null>;
  companyName: Ref<string | null>;
  email: Ref<string | null>;
  employee: Employee;
  address: Address;
}

export const useCurrentUser = createSharedComposable((): UseCurrentUserType => {
  const userId = useStorage(USER_ID_KEY, null);
  const companyId = useStorage(COMPANY_ID_KEY, null);

  const email = useStorage(EMAIL_KEY, null);
  const firstName = useStorage(FIRST_NAME_KEY, null);
  const lastName = useStorage(LAST_NAME_KEY, null);
  const fullName = useStorage(FULL_NAME_KEY, null);

  const companyName = useStorage(COMPANY_KEY, null);

  const employee: Employee = {
    firstName: 'Maximilian',
    lastName: 'Mustermann',
    email: 'max.mustermann@kurt-koenig.de',
    phone: '+ 49 5561 7901 – 0',
    companyName: 'Niederlassung Einbeck',
    title: 'Jobbezeichnung',
  };

  // Eventually the current user will need to store the banch address associated to them
  const address: Address = {
    id: '1',
    companyName: 'Vioki GmbH',
    taxId: 'Vioki GmbH',
    street: 'Savanoriu',
    houseNumber: '100',
    postCode: '12345',
    city: 'Kaunas',
    country: 'DE',
    countryCode: 'DE',
  };

  return {
    userId,
    companyId,
    firstName,
    lastName,
    fullName,
    companyName,
    employee,
    address,
    email,
  };
});
