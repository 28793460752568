import { ResultAsync } from '@/shared/utils/result';
import { ApiAuth } from '@/shared/interfaces/api/ApiAuth';
import { LoginForm } from '@/shared/interfaces/forms/LoginForm';

export enum LoginError {
  WrongCredentials,
  UnknownError,
}

export interface Login {
  logIn(data: LoginForm): ResultAsync<ApiAuth, LoginError>;
}
