import { LocaleMessages, VueMessageType, createI18n } from 'vue-i18n';
import { datetimeFormats } from '@/root/locales/dates';
import { numberFormats } from '@/root/locales/numbers';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: 'tech',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: loadLocaleMessages() as any,
  // Do not show warning for missing keys
  fallbackWarn: false,
  missingWarn: false,
  datetimeFormats,
  numberFormats,
});
