import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync, err } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentAttachments, RentAttachmentsOptions } from '@/shared/interfaces/rentAttachments';
import { RentAttachment } from '@/shared/graphql/graphql';

export class RentAttachmentsService implements RentAttachments {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getAttachments(
    options: RentAttachmentsOptions
  ): ResultAsync<RentAttachment[], IGraphQLError> {
    const getAttachmentsQuery = graphql(`
      query getAttachments(
        $productId: Uuid!
        $branch: Uuid!
        $rent: DateInterval
        $buffer: DateInterval
      ) {
        rentAttachments(productId: $productId, branch: $branch, rent: $rent, buffer: $buffer) {
          items {
            id
            name
            availability {
              id
              branch
              available
            }
            pricePerDay {
              amount
              amountInCents
              currency
            }
            images {
              type
              path
            }
          }
        }
      }
    `);

    const response = await this.graphql.query(getAttachmentsQuery, options, 'network-only');
    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.rentAttachments?.items.map((item) => item) as RentAttachment[]);
  }
}
