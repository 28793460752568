import { datadogRum } from '@datadog/browser-rum';

export const datadog = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install: (app: any) => {
    if (process.env.VUE_APP_DD_APP_ID) {
      datadogRum.init({
        applicationId: process.env.VUE_APP_DD_APP_ID,
        clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: process.env.VUE_APP_NUXT_DD_APP_ID,
        env: process.env.VUE_APP_ENV,
        sessionSampleRate: parseInt(process.env.VUE_APP_DD_CLIENT_SAMPLE_RATE as string, 10), // Out of 100
        sessionReplaySampleRate: 1, // Out of 100
        trackUserInteractions: true,
        trackResources: false,
        trackLongTasks: false,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
    return app;
  },
};
