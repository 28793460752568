import { permissions } from '@/shared/interfaces/permissions';

export const routes = {
  login: {
    path: '/login',
    name: 'login',
    meta: {
      requiresGuest: true,
    },
  },
  dashboard: {
    path: '/',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
    },
  },
  registration: {
    path: '/registration',
    name: 'Registration',
    meta: {
      requiresAuth: true,
      permission: permissions.create_customer_registration,
    },
  },
  importedClientRegistrationInvite: {
    path: (id: string): string => `/imported-clients/registration/${id}`,
    name: 'Imported client registration invite',
    meta: {
      requiresAuth: true,
      permission: permissions.update_customer_invitation,
    },
  },
  clients: {
    path: '/clients',
    name: 'Clients',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customers,
    },
  },
  clientDetails: {
    path: (id: string): string => `/clients/${id}`,
    name: 'Client details',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customers,
    },
  },
  clientEdit: {
    path: (id: string): string => `/clients/${id}/edit`,
    name: 'Client details edit',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_information,
    },
  },
  clientLoginData: {
    path: (id: string): string => `/clients/${id}/login-data`,
    name: 'Client login data edit',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_login,
    },
  },
  clientBilling: {
    path: (id: string): string => `/clients/${id}/billing`,
    name: 'Client billing settings',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_communication,
    },
  },
  clientContacts: {
    path: (id: string): string => `/clients/${id}/contacts`,
    name: 'Client contacts',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_communication,
    },
  },
  clientOrders: {
    path: (id: string): string => `/clients/${id}/orders`,
    name: 'Client orders',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_orders,
    },
  },
  clientAddressbook: {
    path: (id: string): string => `/clients/${id}/addressbook`,
    name: 'Client addressbook',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_communication,
    },
  },
  clientAddresses: {
    path: (id: string): string => `/clients/${id}/addresses`,
    name: 'Client addresses',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_communication,
    },
  },
  clientMainAddress: {
    path: (id: string): string => `/clients/${id}/main-address`,
    name: 'Client main address',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_communication,
    },
  },
  clientOrderDetails: {
    path: (id: string, orderId: string): string => `/clients/${id}/orders/${orderId}`,
    name: 'Client order details',
    meta: {
      requiresAuth: true,
      permission: permissions.read_customer_orders,
    },
  },
  ordersHistory: {
    path: '/orders-history',
    name: 'Orders History',
    meta: {
      requiresAuth: true,
      permission: permissions.read_orders,
    },
  },
  orderDetails: {
    path: (orderId: string): string => `/orders/${orderId}`,
    name: 'Order details',
    meta: {
      requiresAuth: true,
      permission: permissions.read_orders,
    },
  },
  orderCreate: {
    path: `/orders/create`,
    name: 'Create order page',
    meta: {
      requiresAuth: true,
      permission: permissions.create_sales_order,
    },
  },
  search: {
    path: '/search',
    name: 'Search Results',
    meta: {
      requiresAuth: true,
      permission: permissions.read_products,
    },
  },
  productOverview: {
    path: '/products',
    name: 'Products',
    meta: {
      requiresAuth: true,
      permission: permissions.read_products,
    },
  },
  productDetails: {
    path: (productId: string): string => `/products/${productId}`,
    name: 'Product details page',
    meta: {
      requiresAuth: true,
      permission: permissions.read_products,
    },
  },
  rentOrders: {
    path: '/rent/orders',
    name: 'Rent orders page',
    meta: {
      requiresAuth: true,
    },
  },
  rentOrderDetails: {
    path: (orderId: string): string => `/rent/orders/${orderId}`,
    name: 'Rent order details page',
    meta: {
      requiresAuth: true,
    },
  },
  rentProductDetails: {
    path: (productId: string): string => `/rent/products/${productId}`,
    name: 'Rent product details page',
    meta: {
      requiresAuth: true,
    },
  },
  rentProductOverview: {
    path: '/rent/products',
    name: 'Rent products',
    meta: {
      requiresAuth: true,
    },
  },
  rentCheckout: {
    path: '/rent/checkout',
    name: 'Rent checkout',
    meta: {
      requiresAuth: true,
    },
  },
  users: {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true,
      permission: permissions.read_user_roles,
    },
  },
};
