import { Ref, ref } from 'vue';

interface MaintenanceStore {
  isUnderMaintenance: Ref<boolean>;
  setIsUnderMaintenance: (value: boolean) => void;
}

const isUnderMaintenance = ref<boolean>(false);

export const useMaintenanceStore = (): MaintenanceStore => {
  isUnderMaintenance.value = false;
  const setIsUnderMaintenance = (value: boolean) => {
    isUnderMaintenance.value = value;
  };

  return {
    isUnderMaintenance,
    setIsUnderMaintenance,
  };
};
