import { ref } from 'vue';
import type { PaymentOption } from '@venginetech/ui-library/dist/types/components/inputs/PaymentInput/paymentOption';
import { TranslationFun } from '@/shared/composables/useTranslations';

export type PaymentType = 'invoice';
export const PAYMENT_TYPE: Record<string, PaymentType> = {
  invoice: 'invoice',
};

export const usePaymentOptions = (t: TranslationFun) => {
  const invoice: PaymentOption = {
    value: PAYMENT_TYPE.invoice,
    title: t('payment_option_invoice'),
    label: t('payment_option_invoice_label'),
    icon: 'kurtKoningLogo',
  };

  const selectedOption = ref(invoice.value);

  // TODO: uncomment when additional payment methods are available
  // const creditcard: PaymentOption = {
  //   value: 'creditcard',
  //   title: t('payment_option_credit'),
  //   label: t('payment_option_credit_label'),
  //   icon: 'visaAndMastercard',
  // };

  // const paypal: PaymentOption = {
  //   value: 'paypal',
  //   title: t('payment_option_paypal'),
  //   label: t('payment_option_paypal_label'),
  //   icon: 'payPal',
  // };

  const options: PaymentOption[] = [invoice];

  return { selectedOption, options };
};
