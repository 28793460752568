import { Router, START_LOCATION } from 'vue-router';
import { DebouncedFunc } from 'lodash';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import { Auth } from '@/shared/interfaces/auth';
import { IApi, Response, ResponseError } from '@/shared/interfaces/api';
import { ResultAsync } from '@/shared/utils/result';
import { ApiAuth } from '@/shared/interfaces/api/ApiAuth';
import { LoginForm } from '@/shared/interfaces/forms/LoginForm';
import { IStorage } from '@/shared/services/storage';
import { routes } from '@/shared/constants/routes';
import { Logger } from '@/shared/services/logger';
import { DecodedJWT } from '@/shared/interfaces/others/DecodedJWT';

const REFRESH_TOKEN_THROTTLE_TIME = 300;

export class AuthService implements Auth {
  api: IApi;

  storage: IStorage;

  router: Router;

  logger: Logger;

  token?: string;

  roles?: string[];

  validTo?: Date;

  callRefresh: DebouncedFunc<() => ResultAsync<Response<ApiAuth>, ResponseError>>;

  subscribers: (() => void)[];

  constructor(api: IApi, storage: IStorage, router: Router, logger: Logger) {
    this.api = api;
    this.storage = storage;
    this.router = router;
    this.logger = logger;
    this.callRefresh = throttle(this.refresh, REFRESH_TOKEN_THROTTLE_TIME);
    this.subscribers = [];
    this.setRouteGuards();
  }

  setRouteGuards(): void {
    this.router.beforeEach(async (to, from) => {
      // This is to avoid calling refresh when the query params change
      const queryChanged = !isEqual(to.query, from.query) && from !== START_LOCATION;
      const authenticated = this.isAuthenticated();
      if (to.meta.requiresAuth && !queryChanged && !authenticated) {
        // Try refreshing the token
        const result = await this.refresh();
        if (result.isErr()) {
          return { path: routes.login.path, query: { to: to.fullPath } };
        }
        return true;
      }
      if (to.meta.requiresGuest && authenticated) {
        return routes.dashboard.path;
      }
      return true;
    });
  }

  async login(data: LoginForm): ResultAsync<Response<ApiAuth>, ResponseError> {
    const Authorization = `Basic ${window.btoa(`${data.email}:${data.password}`)}`;
    const response = await this.api.post<ApiAuth>('/employee-login', {
      headers: { Authorization },
      credentials: 'include',
    });
    if (response.isOk()) {
      this.setToken(response.value.data);
    }
    return response;
  }

  async refresh(): ResultAsync<Response<ApiAuth>, ResponseError> {
    const response = await this.api.post<ApiAuth>('/employee-refresh-token', {
      credentials: 'include',
    });
    if (response.isOk()) {
      this.setToken(response.value.data);
    } else {
      this.resetLocalState();
      this.router.push(routes.login.path);
    }
    return response;
  }

  setToken(data: ApiAuth): void {
    const { access_token: token, valid_to: validTo } = data;
    this.token = token;
    this.validTo = new Date(validTo);
    this.roles = this.decodeJWT<DecodedJWT>(token).roles;
    this.notifySubscribers();
  }

  subscribe(subscriber: () => void): void {
    this.subscribers.push(subscriber);
  }

  notifySubscribers(): void {
    this.subscribers.forEach((subscriber) => subscriber());
  }

  getRoles(): string[] {
    return this.roles ?? [];
  }

  getAuthToken(): string {
    return this.token ?? '';
  }

  isAuthenticated(): boolean {
    return !!this.token && !!this.validTo && this.validTo > new Date();
  }

  async logout(): Promise<void> {
    await this.api.post<ApiAuth>('/employee-logout', {
      credentials: 'include',
    });
    this.resetLocalState();
  }

  resetLocalState(): void {
    this.token = undefined;
    this.validTo = undefined;
    this.storage.clearAll();
  }

  decodeJWT<T>(token: string): T | Record<string, never> {
    let result = {};
    try {
      result = token
        .split('.')
        .slice(0, -1)
        .reduce(
          (prev, curr) => ({
            ...prev,
            ...JSON.parse(window.atob(curr)),
          }),
          {}
        );
    } catch (error) {
      this.logger.error('Failed to parse JWT token', error, { token });
    }
    return result;
  }
}
