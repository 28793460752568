<template>
  <img :src="source" alt="icon" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IMAGES, useImage } from '@/shared/composables/useImage';

export default defineComponent({
  name: 'BaseImage',
  props: {
    icon: {
      type: String as PropType<keyof typeof IMAGES>,
      required: true,
    },
  },
  setup(props) {
    const source = useImage(props.icon as keyof typeof IMAGES);

    return {
      source,
    };
  },
});
</script>
