import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi } from '@/shared/interfaces/api';
import { GenericResponse } from '@/shared/interfaces/genericResponse';
import { KkBranches } from '@/shared/interfaces/services/kkBranches';
import { ApiBranch } from '@/shared/interfaces/api/ApiBranch';

export class KkBranchService implements KkBranches {
  api: IApi;

  logger: Logger;

  constructor(api: IApi, logger: Logger) {
    this.api = api;
    this.logger = logger;
  }

  async getBranches(): Promise<ApiBranch[]> {
    const response = await this.api.get<GenericResponse<ApiBranch>>(API_LINK.branches);
    if (response.isErr()) {
      return [];
    }
    return response.value.data.items;
  }
}
