import { Permission } from '@/shared/interfaces/permissions';
import { Role } from '@/shared/interfaces/roles';

export const roleMapping: Record<Role, Permission[]> = {
  ROLE_SUPER_ADMIN: [
    'read_products',
    'read_orders',
    'read_customers',
    'read_customer_orders',
    'update_customer_information',
    'read_customer_information',
    'create_customer_communication',
    'update_customer_communication',
    'delete_customer_communication',
    'read_customer_communication',
    'update_customer_login',
    'read_customer_login',
    'create_customer_registration',
    'update_customer_registration',
    'update_customer_invitation',
    'create_sales_order',
    'create_user_roles',
    'update_user_roles',
    'delete_user_roles',
    'read_user_roles',
    'create_customer_roles',
    'update_customer_roles',
    'delete_customer_roles',
    'read_customer_roles',
  ],
  ROLE_SALES_REPRESENTATIVE: [
    'read_products',
    'read_orders',
    'read_customers',
    'read_customer_orders',
    'update_customer_information',
    'read_customer_information',
    'create_customer_communication',
    'update_customer_communication',
    'delete_customer_communication',
    'read_customer_communication',
    'update_customer_login',
    'read_customer_login',
    'create_customer_registration',
    'update_customer_registration',
    'update_customer_invitation',
    'create_sales_order',
    'create_customer_roles',
    'update_customer_roles',
    'delete_customer_roles',
    'read_customer_roles',
  ],
  ROLE_FINANCE: [
    'read_products',
    'read_orders',
    'read_customers',
    'read_customer_orders',
    'update_customer_information',
    'read_customer_information',
    'create_customer_communication',
    'update_customer_communication',
    'delete_customer_communication',
    'read_customer_communication',
    'read_customer_login',
    'create_customer_registration',
    'update_customer_registration',
    'update_customer_invitation',
    'read_customer_roles',
  ],
  ROLE_VIEWER: [
    'read_products',
    'read_orders',
    'read_customers',
    'read_customer_orders',
    'read_customer_information',
    'read_customer_communication',
    'read_customer_roles',
  ],
};
