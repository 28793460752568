<template>
  <div v-if="breadcrumbRoutes" class="breadcrumbsContainer">
    <router-link
      v-for="route in breadcrumbRoutes"
      :key="route?.name"
      :to="route?.path"
      class="breadcrumbsLink"
    >
      <h4 class="breadcrumbsText">{{ route?.name }}</h4>
      <BaseIcon icon="next" class="breadcrumbsIcon" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { BreadCrumb } from '@/shared/interfaces/models/BreadCrumb';

export default defineComponent({
  name: 'BaseBreadcrumbs',
  props: {
    breadcrumbRoutes: {
      type: Array as PropType<BreadCrumb[]>,
      required: true,
    },
  },
});
</script>

<style scoped>
.breadcrumbsContainer {
  @apply text-g-900 flex items-center;
}

.breadcrumbsLink {
  @apply flex items-center;
}

.breadcrumbsText {
  @apply text-16 capitalize font-medium;
}

.breadcrumbsIcon {
  @apply h-1.5 w-1.5 text-g-900 mx-0.5;
}

.breadcrumbsLink:last-child .breadcrumbsIcon {
  @apply hidden;
}
</style>
