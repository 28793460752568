<template>
  <div v-if="!isProduction" class="base-indicator" :class="environmentStyle.class">
    {{ environmentStyle.value }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EnvironmentIndicatorStyles } from '@/shared/components/atoms/visual/EnvironmentIndicator/EnvironmentIndicatorStyles';

export default defineComponent({
  name: 'EnvironmentIndicator',
  setup() {
    const variable = process.env.VUE_APP_ENV ?? '';
    const environmentStyle =
      EnvironmentIndicatorStyles[variable as keyof typeof EnvironmentIndicatorStyles];
    const isProduction = variable === 'production' || variable === '';
    return {
      environmentStyle,
      isProduction,
    };
  },
});
</script>

<style scoped>
.base-indicator {
  @apply justify-center flex items-center;
  @apply h-3 ml-2.5 px-2 py-2 2xl:mt-[1.05rem];
  @apply uppercase font-semibold tracking-wide;
  @apply rounded-8;
}
</style>
