import { Logger } from '@/shared/services/logger';
import { RentCategories } from '@/shared/interfaces/rentCategories';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { ok, ResultAsync, err } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { ProductCategory, ProductCategoryEnum } from '@/shared/graphql/graphql';

export class RentCategoriesService implements RentCategories {
  graphql: IGgraphQL;

  logger: Logger;

  constructor(graphqlApi: IGgraphQL, logger: Logger) {
    this.graphql = graphqlApi;
    this.logger = logger;
  }

  async getRentCategories(): ResultAsync<ProductCategory[], IGraphQLError> {
    const getRentCategoriesQuery = graphql(`
      query getRentCategories($type: ProductCategoryEnum!, $withProducts: Boolean!) {
        productCategories(type: $type, withProducts: $withProducts) {
          items {
            id
            name
            code
            children {
              id
              name
              code
              children {
                id
                name
                code
                children {
                  id
                  name
                  code
                }
              }
            }
          }
        }
      }
    `);
    const response = await this.graphql.query(getRentCategoriesQuery, {
      type: ProductCategoryEnum.Rental,
      withProducts: true,
    });
    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.productCategories?.items as ProductCategory[]);
  }
}
