const values = [
  'read_products',
  'read_orders',
  'read_customers',
  'read_customer_orders',
  'update_customer_information',
  'read_customer_information',
  'create_customer_communication',
  'update_customer_communication',
  'delete_customer_communication',
  'read_customer_communication',
  'update_customer_login',
  'read_customer_login',
  'create_customer_registration', // this is for customer registration
  'update_customer_registration', // this is for customer registration resend
  'update_customer_invitation', // this is for customer invitation and resend
  'create_sales_order',
  'create_user_roles',
  'update_user_roles',
  'delete_user_roles',
  'read_user_roles',
  'create_customer_roles',
  'update_customer_roles',
  'delete_customer_roles',
  'read_customer_roles',
] as const;

export type Permission = typeof values[number];
export type Permissions = Record<Permission, boolean>;

export const permissions = values.reduce((acc, permission) => {
  acc[permission] = permission;
  return acc;
}, {} as Record<Permission, Permission>);
