import { Ref, shallowRef } from 'vue';

interface IconsType {
  [name: string]: () => Promise<typeof import('*.svg')> | Promise<typeof import('*.png')>;
}

export const IMAGES: IconsType = {
  card: () => import('@/shared/assets/icons/paymentMethods/card.svg'),
  euro: () => import('@/shared/assets/icons/paymentMethods/euro.svg'),
  // TODO replace this png with svg
  klarna: () => import('@/shared/assets/icons/paymentMethods/klarna.png'),
  kurts: () => import('@/shared/assets/icons/paymentMethods/kurts.svg'),
  paypal: () => import('@/shared/assets/icons/paymentMethods/paypal.svg'),
  sofort: () => import('@/shared/assets/icons/paymentMethods/sofort.svg'),
  kreditkarte: () => import('@/shared/assets/icons/paymentMethods/visa.svg'),
  vorkasse: () => import('@/shared/assets/icons/paymentMethods/vorkasse.svg'),
  search: () => import('@/shared/assets/search.svg'),
  filter: () => import('@/shared/assets/ic_filter.svg'),
  add: () => import('@/shared/assets/ic_add.svg'),
  download: () => import('@/shared/assets/download.svg'),
  edit: () => import('@/shared/assets/ic_edit.svg'),
  editLight: () => import('@/shared/assets/ic_edit_light.svg'),
  bin: () => import('@/shared/assets/ic_bin.svg'),
  kurtLogo: () => import('@/shared/assets/ic_logo.svg'),
  continue: () => import('@/shared/assets/arrow_continue.svg'),
  available: () => import('@/shared/assets/ic_status_available.svg'),
  unavailable: () => import('@/shared/assets/ic_status_unavailable.svg'),
  arrowBack: () => import('@/shared/assets/ic_arrow_back.svg'),
  arrowBackTablet: () => import('@/shared/assets/ic_arrow_back_tablet.svg'),
};

export const useImage = (iconName: keyof typeof IMAGES): Ref<null> => {
  const icon = shallowRef(null);
  if (IMAGES[iconName]) {
    IMAGES[iconName]().then((res) => {
      icon.value = res.default;
    });
  }
  return icon;
};
