import { Ref, ref } from 'vue';
import { Notification } from '@/shared/interfaces/notifications';

interface UseNotificationsReturns {
  notifications: Ref<Notification[]>;
  pushNotification: ({ title, label, type }: Notification) => void;
  removeNotification: (key: string) => void;
}

const notifications: Ref<Notification[]> = ref([]);

export const useNotifications = (): UseNotificationsReturns => {
  const getRandomHash = () => {
    return Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
  };

  const removeNotification = (key: string) => {
    notifications.value = notifications.value.filter((notif) => notif.key !== key);
  };

  const pushNotification = (notification: Notification) => {
    notifications.value.push({ key: getRandomHash(), ...notification });
  };

  return { notifications, pushNotification, removeNotification };
};
