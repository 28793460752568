import { defineAsyncComponent } from 'vue';
import { RouteRecordRaw, createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import { routes } from '@/shared/constants/routes';

// Feature page example
// {
//   ...routes.page,
//   component: () =>
//     import(
//       /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
//     ),
//   props: {
//     feature: 'fe_kkdig_xxxx_feature',
//     target: defineAsyncComponent(() => import('@/pages/page/page.vue')),
//     fallback: defineAsyncComponent(() => import('@/pages/page/pageOld.vue')),
//   },
// },

const routeConfig: Array<RouteRecordRaw> = [
  {
    ...routes.login,
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/LoginPage.vue'),
  },
  {
    ...routes.dashboard,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/pages/dashboard/DashboardPage.vue'),
  },
  {
    ...routes.registration,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/pages/registration/RegistrationPage.vue'),
  },
  {
    ...routes.importedClientRegistrationInvite,
    path: routes.importedClientRegistrationInvite.path(':companyId'),
    component: () =>
      import(
        /* webpackChunkName: "ImportedClientRegistrationPageInvite" */ '@/pages/registrationInvite/RegistrationInvitePage.vue'
      ),
  },
  {
    ...routes.clients,
    component: () => import(/* webpackChunkName: "clients" */ '@/pages/client/ClientsPage.vue'),
  },
  {
    ...routes.clientDetails,
    path: routes.clientDetails.path(':id'),
    component: () =>
      import(/* webpackChunkName: "clientDetails" */ '@/pages/client/ClientDashboardPage.vue'),
  },
  {
    ...routes.clientEdit,
    path: routes.clientEdit.path(':id'),
    component: () =>
      import(/* webpackChunkName: "clientEdit" */ '@/pages/client/ClientEditPage.vue'),
  },
  {
    ...routes.clientLoginData,
    path: routes.clientLoginData.path(':id'),
    component: () =>
      import(/* webpackChunkName: "clientLoginData" */ '@/pages/client/ClientLoginDataPage.vue'),
  },
  {
    ...routes.clientBilling,
    path: routes.clientBilling.path(':id'),
    component: () =>
      import(
        /* webpackChunkName: "clientBillingEdit" */ '@/pages/clientBilling/ClientBillingPage.vue'
      ),
  },
  {
    ...routes.clientContacts,
    path: routes.clientContacts.path(':id'),
    component: () =>
      import(
        /* webpackChunkName: "clientContactEdit" */ '@/pages/clientContacts/ClientContactsPage.vue'
      ),
  },
  {
    ...routes.clientOrders,
    path: routes.clientOrders.path(':id'),
    component: () =>
      import(/* webpackChunkName: "clientOrders" */ '@/pages/clientOrders/ClientOrdersPage.vue'),
  },
  {
    ...routes.clientAddressbook,
    path: routes.clientAddressbook.path(':id'),
    component: () =>
      import(/* webpackChunkName: "clientAddressbook" */ '@/pages/client/ClientAddressbook.vue'),
  },
  {
    ...routes.clientAddresses,
    path: routes.clientAddresses.path(':id'),
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'showOutputChannel',
      target: defineAsyncComponent(() => import('@/pages/clientAddresses/ClientAddressesPage.vue')),
      fallback: defineAsyncComponent(
        () => import('@/pages/clientAddresses/ClientAddressesPageOld.vue')
      ),
    },
  },
  {
    ...routes.clientMainAddress,
    path: routes.clientMainAddress.path(':id'),
    component: () =>
      import(
        /* webpackChunkName: "clientAddresses" */ '@/pages/clientAddresses/ClientMainAddressPage.vue'
      ),
  },
  {
    ...routes.clientOrderDetails,
    path: routes.clientOrderDetails.path(':id', ':orderId'),
    component: () =>
      import(
        /* webpackChunkName: "clientOrderDetails" */ '@/pages/clientOrderDetails/ClientOrderDetailsPage.vue'
      ),
  },
  {
    ...routes.ordersHistory,
    component: () =>
      import(/* webpackChunkName: "orderHistory" */ '@/pages/order/OrderHistoryPage.vue'),
  },
  {
    ...routes.orderDetails,
    path: routes.orderDetails.path(':orderId'),
    component: () =>
      import(/* webpackChunkName: "orderDetails" */ '@/pages/orderDetails/OrderDetailsPage.vue'),
  },
  {
    ...routes.orderCreate,
    component: () => import(/* webpackChunkName: "checkout" */ '@/pages/checkout/CheckoutPage.vue'),
  },
  {
    ...routes.search,
    path: routes.search.path,
    component: () => import(/* webpackChunkName: "search" */ '@/pages/search/SearchPage.vue'),
  },
  {
    ...routes.productOverview,
    path: routes.productOverview.path,
    component: () =>
      import(
        /* webpackChunkName: "productOverview" */ '@/pages/productOverview/ProductOverviewPage.vue'
      ),
  },
  {
    ...routes.productDetails,
    path: routes.productDetails.path(':productId'),
    component: () =>
      import(
        /* webpackChunkName: "productDetails" */ '@/pages/productDetails/ProductDetailsPage.vue'
      ),
  },
  {
    ...routes.rentOrders,
    path: routes.rentOrders.path,
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'fe_ren_rental_enabled',
      target: defineAsyncComponent(
        () => import(/* webpackChunkName: "rentOrders" */ '@/pages/rentOrders/RentOrdersPage.vue')
      ),
    },
  },
  {
    ...routes.rentOrderDetails,
    path: routes.rentOrderDetails.path(':orderId'),
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'fe_ren_rental_enabled',
      target: defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "rentOrders" */ '@/pages/rentOrderDetails/RentOrderDetailsPage.vue'
          )
      ),
    },
  },
  {
    ...routes.rentProductDetails,
    path: routes.rentProductDetails.path(':productId'),
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'fe_ren_rental_enabled',
      target: defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "rentProduct" */ '@/pages/rentProductDetails/RentProductDetailsPage.vue'
          )
      ),
    },
  },
  {
    ...routes.rentProductOverview,
    path: routes.rentProductOverview.path,
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'fe_ren_rental_enabled',
      target: defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "rentOverviewPage" */ '@/pages/rentProductOverview/RentProductOverviewPage.vue'
          )
      ),
    },
  },
  {
    ...routes.rentCheckout,
    path: routes.rentCheckout.path,
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'fe_ren_rental_enabled',
      target: defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "rentCheckoutPage" */ '@/pages/rentCheckout/RentCheckoutPage.vue'
          )
      ),
    },
  },
  {
    ...routes.users,
    path: routes.users.path,
    component: () =>
      import(
        /* webpackChunkName: "chunkName" */ '@/shared/components/organisms/FeaturePage/FeaturePage.vue'
      ),
    props: {
      feature: 'fe_cs2_39_users_page',
      target: defineAsyncComponent(
        () => import(/* webpackChunkName: "users" */ '@/pages/users/UsersPage.vue')
      ),
    },
  },
];

export const router = createRouter({
  history: process.env.VUE_APP_ENABLE_HASH_ROUTER
    ? createWebHashHistory(process.env.BASE_URL)
    : createWebHistory(process.env.BASE_URL),
  routes: routeConfig,
  scrollBehavior: (to, from, scroll) => (from.path === to.path ? { top: scroll?.top } : { top: 0 }),
});
