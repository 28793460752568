import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi } from '@/shared/interfaces/api';
import { GenericResponse } from '@/shared/interfaces/genericResponse';
import { Employees } from '@/shared/interfaces/services/employees';
import { ApiEmployee } from '@/shared/interfaces/api/ApiEmployee';

export class EmployeesService implements Employees {
  api: IApi;

  logger: Logger;

  constructor(api: IApi, logger: Logger) {
    this.api = api;
    this.logger = logger;
  }

  async getEmployees(isAssigned = false): Promise<ApiEmployee[]> {
    const params = new URLSearchParams();
    params.set('pagination', 'false');
    params.set('showAssignedEmployees', `${isAssigned}`);
    const response = await this.api.get<GenericResponse<ApiEmployee>>(API_LINK.employees(params));
    if (response.isErr()) {
      return [];
    }
    return response.value.data.items;
  }
}
