import { computed } from 'vue';
import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync, err } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentOrders, RentPriceCheckOptions } from '@/shared/interfaces/rentOrders';
import { RentOrderInput, Void } from '@/shared/graphql/graphql';
import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { ApiRentOrder, ApiRentOrderSearchOption } from '@/shared/interfaces/api/ApiRentOrder';
import { RentOrderFilterOptions } from '@/pages/rentOrders/interfaces/rentOrderFilters';
import { ApiRentPriceCheck } from '@/shared/interfaces/api/ApiRentPriceCheck';
import { ApiRentOrderDetails } from '@/shared/interfaces/api/ApiRentOrderDetails';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_SEARCH_LIST_SIZE = 10;

export class RentOrdersService implements RentOrders {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async createOrder(order: RentOrderInput): ResultAsync<Void, IGraphQLError> {
    const createRentOrderMutation = graphql(`
      mutation createOrder($input: RentOrderInput!) {
        createRentOrder(input: $input) {
          void
        }
      }
    `);

    const response = await this.graphql.mutate(createRentOrderMutation, { input: order });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }

  async getOrderDetails(rentOrderId: string): ResultAsync<ApiRentOrderDetails, IGraphQLError> {
    const getRentOrderQuery = graphql(`
      query getRentOrder($rentOrderId: Uuid!) {
        rentOrder(id: $rentOrderId) {
          id
          internalId
          rent
          employee {
            id
            firstName
            lastName
            workEmail
            branch
            reportingLineUnitId
            status
          }
          deliveryContacts
          invoiceStatus
          status
          sourceSystem
          totals {
            price {
              amount
              amountInCents
              currency
            }
            discountPercentage
            discount {
              amount
              amountInCents
              currency
            }
            priceWithDiscount {
              amount
              amountInCents
              currency
            }
            taxPercentage
            tax {
              amount
              amountInCents
              currency
            }
            finalPrice {
              amount
              amountInCents
              currency
            }
          }
          orderProductAggregates {
            orderProduct {
              id
              productId
              serializedItemId
              parentId
              branch
              rent
            }
            orderProductDetails {
              serializedItemNumber
              name
              quantity
              discountTotal {
                amount
                amountInCents
                currency
              }
              singleDayPrice {
                amount
                amountInCents
                currency
              }
              status
              images {
                path
                type
              }
              categories {
                id
                code
                name
                parent {
                  id
                  code
                  name
                  parent {
                    id
                    code
                    name
                    parent {
                      id
                      code
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

    const response = await this.graphql.query(getRentOrderQuery, { rentOrderId });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.rentOrder as ApiRentOrderDetails);
  }

  async getOrders(
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiRentOrder>, IGraphQLError> {
    const extendedVariables = computed(() => ({
      limit: DEFAULT_PAGE_SIZE,
      ...options,
    }));

    const getOrdersQuery = graphql(`
      query getRentOrders(
        $limit: Int
        $page: Int
        $branches: [Uuid]
        $rent: DateInterval
        $statuses: [RentOrderStatusEnum]
        $search: String
      ) {
        rentOrders(
          limit: $limit
          page: $page
          branches: $branches
          rent: $rent
          statuses: $statuses
          search: $search
        ) {
          items {
            id
            internalId
            rent
            companyName
            discountPercentage
            priceTotal {
              amount
              amountInCents
              currency
            }
            sourceSystem
            status
            invoiceStatus
            orderProductAggregates {
              orderProduct {
                id
                productId
                serializedItemId
                branch
                rent
              }
              orderProductDetails {
                name
                serializedItemNumber
                quantity
                categories {
                  id
                  code
                  name
                  parent {
                    id
                    code
                    name
                    parent {
                      id
                      code
                      name
                      parent {
                        id
                        code
                        name
                      }
                    }
                  }
                }
                singleDayPrice {
                  amount
                  amountInCents
                  currency
                }
                status
                images {
                  type
                  path
                }
              }
            }
          }
          meta {
            current
            itemsPerPage
            last
            totalItems
          }
        }
      }
    `);

    const response = await this.graphql.query(getOrdersQuery, extendedVariables.value);

    if (response.isErr()) {
      return err(response.error);
    }
    return ok({
      items: response.value?.rentOrders?.items,
      ...response.value?.rentOrders?.meta,
    } as PaginatedResource<ApiRentOrder>);
  }

  async getOrderSearchOptions(
    options: RentOrderFilterOptions
  ): ResultAsync<ApiRentOrderSearchOption[], IGraphQLError> {
    const extendedVariables = computed(() => ({
      limit: DEFAULT_SEARCH_LIST_SIZE,
      ...options,
    }));

    const getRentOrderSearchOptionsQuery = graphql(`
      query getRentOrderSearchOptions(
        $limit: Int
        $employees: [Uuid]
        $branches: [Uuid]
        $rent: DateInterval
        $statuses: [RentOrderStatusEnum]
        $search: String
      ) {
        rentOrders(
          limit: $limit
          employees: $employees
          branches: $branches
          rent: $rent
          statuses: $statuses
          search: $search
        ) {
          items {
            internalId
            companyName
          }
        }
      }
    `);

    const response = await this.graphql.query(
      getRentOrderSearchOptionsQuery,
      extendedVariables.value
    );
    if (response.isErr()) {
      return err(response.error);
    }

    return ok(response.value?.rentOrders?.items as ApiRentOrderSearchOption[]);
  }

  async priceCheck(options: RentPriceCheckOptions): ResultAsync<ApiRentPriceCheck, IGraphQLError> {
    const priceCheckQuery = graphql(`
      query rentPriceCheck($products: [RentPriceCheckInput]!, $companyId: Uuid) {
        rentPriceCheck(products: $products, companyId: $companyId) {
          totals {
            price {
              amount
              amountInCents
              currency
            }
            discountPercentage
            discount {
              amount
              amountInCents
              currency
            }
            priceWithDiscount {
              amount
              amountInCents
              currency
            }
            taxPercentage
            tax {
              amount
              amountInCents
              currency
            }
            finalPrice {
              amount
              amountInCents
              currency
            }
          }
          productPrices {
            productId
            quantity
            billableDays
            totalDurationPrice {
              amount
              amountInCents
              currency
            }
          }
        }
      }
    `);

    const response = await this.graphql.query(priceCheckQuery, options);

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.rentPriceCheck as ApiRentPriceCheck);
  }
}
