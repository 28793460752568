import * as Sentry from '@sentry/vue';
import { Logger } from '@/shared/services/logger';
import { err, ok, Result, ResultAsync } from '@/shared/utils/result';
import { ROLES_KEY, USERNAME_KEY } from '@/shared/constants/storage';
import { Auth } from '@/shared/interfaces/auth';
import { Login, LoginError } from '@/pages/login/interfaces/login';
import { useStorage } from '@/shared/composables/useStorage';
import { ApiAuth } from '@/shared/interfaces/api/ApiAuth';
import { LoginForm } from '@/shared/interfaces/forms/LoginForm';
import { DecodedJWT } from '@/shared/interfaces/others/DecodedJWT';
import { useCurrentUser } from '@/shared/composables/useCurrentUser';

export class LoginService implements Login {
  auth: Auth;

  logger: Logger;

  constructor(auth: Auth, logger: Logger) {
    this.auth = auth;
    this.logger = logger;
  }

  async logIn(credentials: LoginForm): ResultAsync<ApiAuth, LoginError> {
    const response = await this.auth.login(credentials);
    let result: Result<ApiAuth, LoginError>;
    if (response.isOk()) {
      const { data } = response.value;
      const decodedJwt = this.decodeJWT<DecodedJWT | Record<string, never>>(data.access_token);

      const email = credentials?.email;

      const currentUser = useCurrentUser();

      currentUser.email.value = email;
      currentUser.userId.value = decodedJwt?.identifier;

      useStorage(ROLES_KEY, decodedJwt?.roles);
      useStorage(USERNAME_KEY, decodedJwt?.username);

      Sentry.setUser({ username: email });

      result = ok(data);
    } else {
      const { status } = response.error;
      result = status === 401 ? err(LoginError.WrongCredentials) : err(LoginError.UnknownError);
    }
    return result;
  }

  decodeJWT<T>(token: string): T | Record<string, never> {
    let result = {};
    try {
      result = token
        .split('.')
        .slice(0, -1)
        .reduce(
          (prev, curr) => ({
            ...prev,
            ...JSON.parse(window.atob(curr)),
          }),
          {}
        );
    } catch (error) {
      this.logger.error('Failed to parse JWT token', error, { token });
    }
    return result;
  }
}
