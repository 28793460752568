import { IntlNumberFormats } from 'vue-i18n';

const DEFAULT_CURRENCY_FORMAT = {
  currency: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  currencyShort: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  },
};

const DEFAULT_PERCENTAGE_FORMAT = {
  percent: {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

export const numberFormats: IntlNumberFormats = {
  de: { ...DEFAULT_CURRENCY_FORMAT, ...DEFAULT_PERCENTAGE_FORMAT },
  en: { ...DEFAULT_CURRENCY_FORMAT, ...DEFAULT_PERCENTAGE_FORMAT },
};
