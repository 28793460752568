import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { ApiMe } from '@/shared/interfaces/api/ApiMe';
import { Me } from '@/shared/interfaces/services/me';

export class MeService implements Me {
  api: IApi;

  logger: Logger;

  constructor(api: IApi, logger: Logger) {
    this.api = api;
    this.logger = logger;
  }

  async getMe(): ResultAsync<ApiMe, ViolationErrorMap> {
    const response = await this.api.get<ApiMe>(API_LINK.me);
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }
}
