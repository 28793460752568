import { App } from 'vue';
import { router } from '@/root/router';
import { getConfig } from '@/shared/config';
import { Storage } from '@/shared/services/storage';
import { Logger } from '@/shared/services/logger';
import { FeatureFlags } from '@/shared/services/featureFlags';
import { LocalFeatureFlags } from '@/shared/services/localFeatureFlags';
import { Api } from '@/shared/services/api';
import { GraphQL } from '@/shared/services/graphql';

import {
  FeaturesKey,
  NotificationsKey,
  AuthKey,
  ConfigKey,
  ProductsKey,
  KkBranchKey,
  OrderKey,
  EmployeesKey,
  ClickAndCollectKey,
  MeKey,
  AddressKey,
  DatesKey,
  RentProductsKey,
  RentCartKey,
  RentHolidaysKey,
  RentBufferKey,
  RentAttachmentsKey,
  RentSerializedItemsKey,
  RentOrdersKey,
  RentCategoriesKey,
} from '@/shared/constants/injectables';
import { LoginKey } from '@/pages/login/constants/injectables';
import { RegistrationKey } from '@/pages/registration/constants/injectables';
import { ClientsKey } from '@/pages/client/constants/injectables';
import { ContactPersonsKey } from '@/pages/clientContacts/constants/injectables';
import { CategoriesKey } from '@/pages/productOverview/constants/injectables';

import { AuthService } from '@/shared/services/authService';
import { NotificationService } from '@/shared/services/notificationService';
import { DatesService } from '@/shared/services/datesService';
import { MeService } from '@/shared/services/meService';
import { LoginService } from '@/pages/login/services/loginService';
import { RegistrationService } from '@/pages/registration/services/registrationService';
import { AddressService } from '@/shared/services/addressService';
import { ClientsService } from '@/pages/client/services/clientsService';
import { ProductsService } from '@/pages/order/services/productsService';
import { KkBranchService } from '@/shared/services/kkBranchService';
import { OrderService } from '@/shared/services/orderService';
import { EmployeesService } from '@/shared/services/employeesService';
import { ClickAndCollectService } from '@/shared/services/clickAndCollectService';
import { CategoriesService } from '@/pages/productOverview/services/categoriesService';
import { ContactUniquenessService } from '@/pages/clientContacts/services/contactUniquenessService';
import { RentProductsService } from '@/shared/services/rentProductsService';
import { RentCartService } from '@/shared/services/rentCartService';
import { RentBufferService } from '@/shared/services/rentBufferService';
import { RentAttachmentsService } from '@/shared/services/rentAttachmentsService';
import { RentSerializedItemsService } from '@/shared/services/rentSerializedItemsService';
import { RentHolidaysService } from '@/shared/services/rentHolidaysService';
import { RentOrdersService } from '@/shared/services/rentOrdersService';
import { RentCategoriesService } from '@/shared/services/rentCategoriesService';

import { UsersKey } from '@/pages/users/constants/injectables';
import { UsersService, authApiHeaders } from '@/pages/users/services/usersService';

export const config = getConfig();
export const storage = new Storage();
export const logger = new Logger();
export const featureFlags = new FeatureFlags(storage);
export const dates = new DatesService();
export const notifications = new NotificationService();
export const authApi = new Api({ apiUrl: config.apiAuthUrl, headers: {} }, logger);
export const auth = new AuthService(authApi, storage, router, logger);
export const baseApi = new Api({ apiUrl: config.apiBaseUrl, auth }, logger);
export const rootApi = new Api({ apiUrl: config.apiUrl, auth }, logger);
export const employeeApi = new Api({ apiUrl: config.apiEmployeeUrl, auth }, logger);
export const graphqlApi = new GraphQL({ apiUrl: config.apiGraphQLUrl, auth }, logger);
export const authorizedAuthApi = new Api(
  { apiUrl: config.apiAuthUrl, auth, headers: authApiHeaders },
  logger
);

export const login = new LoginService(auth, logger);
export const registration = new RegistrationService(employeeApi, rootApi, graphqlApi, logger);
export const clients = new ClientsService(employeeApi, graphqlApi, logger);
export const address = new AddressService(employeeApi);
export const products = new ProductsService(employeeApi, graphqlApi, logger);
export const orderService = new OrderService(employeeApi, logger);
export const kkBranch = new KkBranchService(employeeApi, logger);
export const employees = new EmployeesService(employeeApi, logger);
export const clickAndCollect = new ClickAndCollectService(employeeApi, logger);
export const categories = new CategoriesService(rootApi, graphqlApi, featureFlags, logger);
export const me = new MeService(employeeApi, logger);
export const contactPerson = new ContactUniquenessService(employeeApi);
export const users = new UsersService(authorizedAuthApi, employees, logger);
export const rentProducts = new RentProductsService(rootApi, graphqlApi, logger);
export const rentCart = new RentCartService(rootApi, graphqlApi, logger);
export const rentHolidays = new RentHolidaysService(graphqlApi, logger);
export const rentBuffer = new RentBufferService(rootApi, graphqlApi, logger);
export const rentAttachments = new RentAttachmentsService(rootApi, graphqlApi, logger);
export const rentSerializedItems = new RentSerializedItemsService(rootApi, graphqlApi, logger);
export const rentOrders = new RentOrdersService(rootApi, graphqlApi, logger);
export const rentCategories = new RentCategoriesService(graphqlApi, logger);

export const mainComposition = {
  install: (app: App): App => {
    app.provide(AuthKey, auth);
    app.provide(ConfigKey, config);
    app.provide(FeaturesKey, featureFlags);
    app.provide(DatesKey, dates);
    app.provide(NotificationsKey, notifications);
    app.provide(LoginKey, login);
    app.provide(RegistrationKey, registration);
    app.provide(ClientsKey, clients);
    app.provide(AddressKey, address);
    app.provide(ProductsKey, products);
    app.provide(OrderKey, orderService);
    app.provide(KkBranchKey, kkBranch);
    app.provide(EmployeesKey, employees);
    app.provide(ClickAndCollectKey, clickAndCollect);
    app.provide(CategoriesKey, categories);
    app.provide(MeKey, me);
    app.provide(ContactPersonsKey, contactPerson);
    app.provide(UsersKey, users);
    app.provide(RentProductsKey, rentProducts);
    app.provide(RentCartKey, rentCart);
    app.provide(RentHolidaysKey, rentHolidays);
    app.provide(RentBufferKey, rentBuffer);
    app.provide(RentAttachmentsKey, rentAttachments);
    app.provide(RentSerializedItemsKey, rentSerializedItems);
    app.provide(RentOrdersKey, rentOrders);
    app.provide(RentCategoriesKey, rentCategories);
    return app;
  },
};

const localFeatureFlags = new LocalFeatureFlags();

export const mockComposition = {
  install: (app: App): App => {
    mainComposition.install(app);
    // Override only the mocked places
    app.provide(FeaturesKey, localFeatureFlags);
    return app;
  },
};

export const composition = featureFlags.isEnabled('mock') ? mockComposition : mainComposition;
