<template>
  <component :is="component || `h${level}`" :class="classes">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  BaseHeadingStyles,
  levels,
  LevelTypes,
  SizeTypes,
} from '@/shared/components/atoms/visual/BaseHeading/BaseHeadingStyles';

export default defineComponent({
  name: 'BaseHeading',
  props: {
    component: {
      type: String,
      default: undefined,
    },
    level: {
      type: Number as PropType<LevelTypes>,
      default: levels['1'],
      validator: (value: number) => {
        return Object.values(levels).includes(value);
      },
    },
    size: {
      type: Number as PropType<SizeTypes>,
      default: undefined,
      validator: (value: number) => {
        return Object.values(levels).includes(value);
      },
    },
  },
  computed: {
    classes(): string[] {
      const { base, sizes } = BaseHeadingStyles;
      if (this.size === undefined) {
        return [base, sizes[this.level]];
      }
      return [base, sizes[this.size]];
    },
  },
});
</script>
