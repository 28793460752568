import { Addresses } from '@/shared/interfaces/services/addresses';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { ApiCompanyLocation } from '@/shared/interfaces/api/ApiCompanyLocation';

export class AddressService implements Addresses {
  api: IApi;

  constructor(api: IApi) {
    this.api = api;
  }

  async getAddress(locationID: string): ResultAsync<ApiCompanyLocation, ViolationErrorMap> {
    const response = await this.api.get<ApiCompanyLocation>(API_LINK.location(locationID));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }
}
