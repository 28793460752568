import { InjectionKey } from 'vue';

import { Config } from '@/shared/interfaces/config';
import { Features } from '@/shared/interfaces/features';
import { Notifications } from '@/shared/interfaces/notifications';
import { Auth } from '@/shared/interfaces/auth';
import { Me } from '@/shared/interfaces/services/me';

import { Products } from '@/pages/order/interfaces/products';
import { KkBranches } from '@/shared/interfaces/services/kkBranches';
import { Orders } from '@/shared/interfaces/services/orders';
import { Dates } from '@/shared/interfaces/dates';
import { Employees } from '@/shared/interfaces/services/employees';
import { ClickAndCollect } from '@/shared/interfaces/services/clickAndCollect';
import { Addresses } from '@/shared/interfaces/services/addresses';

import { RentProducts } from '@/shared/interfaces/rentProducts';
import { RentCart } from '@/shared/interfaces/rentCart';
import { RentBuffer } from '@/shared/interfaces/rentBuffer';
import { RentAttachments } from '@/shared/interfaces/rentAttachments';
import { RentSerializedItems } from '@/shared/interfaces/rentSerializedItems';
import { RentHolidays } from '@/shared/interfaces/rentHolidays';
import { RentOrders } from '@/shared/interfaces/rentOrders';
import { RentCategories } from '@/shared/interfaces/rentCategories';

export const ConfigKey: InjectionKey<Config> = Symbol('config');
export const FeaturesKey: InjectionKey<Features> = Symbol('features');
export const NotificationsKey: InjectionKey<Notifications> = Symbol('notifications');
export const AuthKey: InjectionKey<Auth> = Symbol('auth');
export const MeKey: InjectionKey<Me> = Symbol('me');

export const ProductsKey: InjectionKey<Products> = Symbol('products');
export const KkBranchKey: InjectionKey<KkBranches> = Symbol('kkBranch');
export const OrderKey: InjectionKey<Orders> = Symbol('order');
export const DatesKey: InjectionKey<Dates> = Symbol('dates');
export const EmployeesKey: InjectionKey<Employees> = Symbol('employees');
export const ClickAndCollectKey: InjectionKey<ClickAndCollect> = Symbol('clickAndCollect');
export const AddressKey: InjectionKey<Addresses> = Symbol('address');

export const RentProductsKey: InjectionKey<RentProducts> = Symbol('rentProducts');
export const RentCartKey: InjectionKey<RentCart> = Symbol('rentCart');
export const RentBufferKey: InjectionKey<RentBuffer> = Symbol('rentBuffer');
export const RentAttachmentsKey: InjectionKey<RentAttachments> = Symbol('rentAttachments');
export const RentSerializedItemsKey: InjectionKey<RentSerializedItems> =
  Symbol('rentSerializedItems');
export const RentHolidaysKey: InjectionKey<RentHolidays> = Symbol('rentHolidays');
export const RentOrdersKey: InjectionKey<RentOrders> = Symbol('rentOrders');
export const RentCategoriesKey: InjectionKey<RentCategories> = Symbol('rentCategories');
