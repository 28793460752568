import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseText = _resolveComponent("BaseText")!

  return (_openBlock(), _createElementBlock("div", {
    "data-test": "notificationBlock",
    class: _normalizeClass(_ctx.$style.notification),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
  }, [
    _createVNode(_component_BaseIcon, {
      icon: _ctx.icon,
      class: "flex-none h-6 w-6"
    }, null, 8, ["icon"]),
    _createElementVNode("div", {
      class: _normalizeClass(["ml-2.5", { 'flex items-center': _ctx.notification.center }])
    }, [
      (_ctx.notification.title)
        ? (_openBlock(), _createBlock(_component_BaseText, {
            key: 0,
            variant: "b1",
            class: "font-bold block mb-0.5"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.notification.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.notification.label)
        ? (_openBlock(), _createBlock(_component_BaseText, {
            key: 1,
            variant: "b2",
            class: "text-g-800"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.notification.label), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}