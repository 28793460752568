import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi } from '@/shared/interfaces/api';
import { ClickAndCollect, ClickAndCollectData } from '@/shared/interfaces/services/clickAndCollect';
import { ApiClickAndCollect } from '@/shared/interfaces/api/ApiClickAndCollect';
import { GenericResponse } from '@/shared/interfaces/genericResponse';

export class ClickAndCollectService implements ClickAndCollect {
  api: IApi;

  logger: Logger;

  constructor(api: IApi, logger: Logger) {
    this.api = api;
    this.logger = logger;
  }

  async getAvailability(products: ClickAndCollectData): Promise<ApiClickAndCollect[]> {
    const body = JSON.stringify(products);
    const response = await this.api.post<GenericResponse<ApiClickAndCollect>>(
      API_LINK.clickAndCollect,
      { body }
    );
    if (response.isErr()) {
      return [];
    }
    return response.value.data.items;
  }
}
