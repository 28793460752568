import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { Orders } from '@/shared/interfaces/services/orders';
import { TotalsCheckForm } from '@/shared/interfaces/forms/OrderForm';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { ApiOrder, ApiOrderTotals } from '@/shared/interfaces/api/ApiOrder';
import { ApiOrderHistory, ApiOrderHistoryLimited } from '@/shared/interfaces/api/ApiOrderHistory';
import { OrderForm as OrderFormV2 } from '@/pages/checkout/interfaces/Checkout';

export class OrderService implements Orders {
  api: IApi;

  logger: Logger;

  constructor(api: IApi, logger: Logger) {
    this.api = api;
    this.logger = logger;
  }

  async getOrderHistory(
    options: DataQueryParams,
    useAbortController = false
  ): ResultAsync<PaginatedResource<ApiOrderHistoryLimited>, ViolationErrorMap> {
    const result = await this.api.getPaginatedResource<ApiOrderHistoryLimited>(
      API_LINK.orderHistory,
      options,
      useAbortController
    );
    if (result.isErr()) {
      const { data } = result.error;
      return err(data);
    }
    return ok(result.value);
  }

  async getOrder(id: string): ResultAsync<ApiOrderHistory, ViolationErrorMap> {
    const response = await this.api.get<ApiOrderHistory>(API_LINK.orderDetails(id));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async createOrderV2(formData: OrderFormV2): ResultAsync<ApiOrder, ViolationErrorMap> {
    const body = JSON.stringify(formData);
    const response = await this.api.post<ApiOrder>(API_LINK.ordersV2(), { body });
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async createOrderV3(formData: OrderFormV2): ResultAsync<ApiOrder, ViolationErrorMap> {
    const body = JSON.stringify(formData);
    const response = await this.api.post<ApiOrder>(API_LINK.ordersV3(), { body });
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async orderTotalsCheck(
    formData: TotalsCheckForm
  ): ResultAsync<ApiOrderTotals, ViolationErrorMap> {
    const body = JSON.stringify(formData);
    const response = await this.api.post<ApiOrderTotals>(API_LINK.orderTotalsCheck, { body }, true);
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }
}
