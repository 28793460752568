import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { Products } from '@/pages/order/interfaces/products';
import { ApiProduct } from '@/shared/interfaces/api/ApiProduct';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { ChannelEnum } from '@/shared/graphql/graphql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';

export const apiFakeProduct: ApiProduct = {
  id: 1,
  name: 'Some product name',
  articleNumber: {
    label: 'SAP ID Test DE',
    value: '0000001',
  },
  manufacturer: {
    label: 'Manufacturer',
    value: {
      id: 1,
      code: 'code',
      name: 'name',
    },
  },
  categories: [
    {
      id: 3,
      name: 'category',
      code: 'cat',
    },
  ],
  description:
    'Boffins ears room whim garb! Here squash can secret eluded inquisitive cloaked She-Elf talked. Cause peace fiddle amaze leader Gamgee firestorm ash carries rightfully cloaked. Sweet-taking Wood-elves seduced sapphire mischief hunting dancing preparing entered was diamond. Gríma myself sawed wished state 4 different? 400 march bank Sounds. Slept bestowing snaffle calls mantelpiece Farthing thereof uncle! Let the Ring-bearer decide.',
  priceNet: {
    amount: '12,00',
    amountInCents: 1200,
    currency: 'EUR',
  },
  priceGross: {
    amount: '14,52',
    amountInCents: 1452,
    currency: 'EUR',
  },
  stockTotal: 100,
  stockMeasurement: 'kg',
  images: [
    {
      '@type': 'ProductImage',
      '@id': '_:831',
      id: 1,
      path: 'https://placeholder.of.today/1920x1280/292520/309167',
      product: '/api/employee/products/1',
      type: 'image',
    },
    {
      '@type': 'ProductImage',
      '@id': '_:841',
      id: 2,
      path: 'https://placeholder.of.today/1920x1280/292520/309167',
      product: '/api/employee/products/1',
      type: 'image',
    },
  ],
  stock: [
    {
      '@type': 'Stock',
      '@id': '_:843',
      id: 1,
      product: '/api/employee/products/1',
      branch: {
        id: '1',
        name: 'Gottingen',
        address: {
          '@type': 'Address',
          '@id': '_:913',
          id: 3,
          street: 'Rand str.',
          houseNumber: '12B',
          city: 'Kaunas',
          postCode: '12312',
          country: 'DE',
        },
        workingHours: [{ name: 'name', workingHours: ['Mondays: 8:00 - 17:00'] }],
        phoneNumbers: ['86868868686886'],
      },
      stock: '12.5',
      onHand: 1,
      onHold: 1,
    },
    {
      '@type': 'Stock',
      '@id': '_:861',
      id: 2,
      product: '/api/employee/products/1',
      branch: {
        id: '1',
        name: 'Gottingen',
        address: {
          '@type': 'Address',
          '@id': '_:913',
          id: 3,
          street: 'Rand str.',
          houseNumber: '12B',
          city: 'Kaunas',
          postCode: '12312',
          country: 'DE',
        },
        workingHours: [{ name: 'name', workingHours: ['Mondays: 8:00 - 17:00'] }],
        phoneNumbers: ['86868868686886'],
      },
      stock: '12.5',
      onHand: 1,
      onHold: 1,
    },
    {
      '@type': 'Stock',
      '@id': '_:862',
      id: 3,
      product: '/api/employee/products/1',
      branch: {
        id: '1',
        name: 'Gottingen',
        address: {
          '@type': 'Address',
          '@id': '_:913',
          id: 3,
          street: 'Rand str.',
          houseNumber: '12B',
          city: 'Kaunas',
          postCode: '12312',
          country: 'DE',
        },
        workingHours: [{ name: 'name', workingHours: ['Mondays: 8:00 - 17:00'] }],
        phoneNumbers: ['86868868686886'],
      },
      stock: '12.5',
      onHand: 1,
      onHold: 1,
    },
  ],
  priceList: [
    {
      price: {
        amount: '67.35',
        amountInCents: 6735,
        currency: 'EUR',
      },
      priceWithVat: {
        amount: '80.14',
        amountInCents: 8014,
        currency: 'EUR',
      },
      quantityBreakpoint: 10,
    },
    {
      price: {
        amount: '70.00',
        amountInCents: 7000,
        currency: 'EUR',
      },
      priceWithVat: {
        amount: '83.30',
        amountInCents: 8330,
        currency: 'EUR',
      },
      quantityBreakpoint: 0,
    },
  ],
  attributes: [
    {
      code: 'att_external_dimensions',
      label: 'Außenmaße',
      type: 'metric',
      value: '5 Meters',
    },
  ],
};

export class ProductsService implements Products {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getProducts(
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiProduct>, ViolationErrorMap> {
    const optionsWithSearchKey = { ...options, searchKey: 'search' };
    const result = await this.api.getPaginatedResource<ApiProduct>(
      API_LINK.products,
      optionsWithSearchKey,
      true
    );
    if (result.isErr()) {
      const { data } = result.error;
      return err(data);
    }
    return ok(result.value);
  }

  async getProduct(id: string): ResultAsync<ApiProduct, ViolationErrorMap> {
    const response = await this.api.get<ApiProduct>(API_LINK.product(id));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  // TODO: the naming is really bad
  async getGraphQLProductOld(id: string): ResultAsync<ApiProduct, IGraphQLError> {
    const getProductQuery = graphql(`
      query getProductOld($channel: ChannelEnum!, $productId: Uuid) {
        product(channel: $channel, id: $productId) {
          id
          priceNetWithDiscount {
            amountInCents
          }
          articleNumber
          brand
          name
          description
          group
          mark
          barcode
          manufacturer
          manufacturerNumber
          priceNet {
            amount
            amountInCents
            currency
          }
          priceNetWithDiscount {
            amount
            amountInCents
            currency
          }
          priceGross {
            amount
            amountInCents
            currency
          }
          stockTotal
          stockMeasurement
          categories {
            id
            code
            name
            shortDescription
            longDescription
            urlSlug
            seoMetaTitle
            seoMetaDescription
            seoMainHeadingTag
            seoKeywords
          }
          priceList {
            quantityBreakpoint
            price {
              amount
              amountInCents
              currency
            }
            priceWithVat {
              amount
              amountInCents
              currency
            }
            priceWithDiscount {
              amount
              amountInCents
              currency
            }
            priceAmount
            priceAmountWithVat
            priceWithDiscount {
              amountInCents
            }
          }
          quantityConfigurations {
            id
            correspondingQuantityTypeCode
            correspondingQuantity
            quantityTypeCode
            quantity
            disabled
          }
          sortiment
          powerAttributes
          technicalAttributes
          equipmentAttributes
          standardsAttributes
          measurementAttributes
          images {
            id
            path
            type
          }
          inSap
          inPim
        }
      }
    `);
    const response = await this.graphql.query(getProductQuery, {
      productId: id,
      channel: ChannelEnum.Ep,
    });
    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.product as ApiProduct);
  }

  // TODO: the naming is really bad
  async getGraphQLProduct(id: string): ResultAsync<ApiProduct, IGraphQLError> {
    const getProductQuery = graphql(`
      query getProduct($channel: ChannelEnum!, $productId: Uuid) {
        product(channel: $channel, id: $productId) {
          id
          articleNumber
          brand
          name
          description
          group
          mark
          manufacturer
          manufacturerNumber
          stockTotal
          stockMeasurement
          categories {
            id
            code
            name
            shortDescription
            longDescription
            urlSlug
            seoMetaTitle
            seoMetaDescription
            seoMainHeadingTag
            seoKeywords
          }
          attributes {
            code
            label
            type
            value
          }
          priceNet {
            amount
            amountInCents
            currency
          }
          priceNetWithDiscount {
            amount
            amountInCents
            currency
          }
          priceGross {
            amount
            amountInCents
            currency
          }
          priceList {
            quantityBreakpoint
            price {
              amount
              amountInCents
              currency
            }
            priceWithVat {
              amount
              amountInCents
              currency
            }
            priceWithDiscount {
              amount
              amountInCents
              currency
            }
            priceAmount
            priceAmountWithVat
            priceWithDiscount {
              amountInCents
            }
          }
          quantityConfigurations {
            id
            correspondingQuantityTypeCode
            correspondingQuantity
            quantityTypeCode
            quantity
            disabled
          }
          sortiment
          images {
            id
            path
            type
          }
          inSap
          inPim
        }
      }
    `);
    const response = await this.graphql.query(getProductQuery, {
      productId: id,
      channel: ChannelEnum.Ep,
    });
    if (response.isErr()) {
      return err(response.error);
    }
    return ok(response.value?.product as ApiProduct);
  }
}
