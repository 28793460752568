import { Notification, NotificationGroup, Notifications } from '@/shared/interfaces/notifications';

export class NotificationService implements Notifications {
  getNotifications = (): Promise<Notification[]> => {
    return Promise.resolve([]);
  };

  groupByDate = (): NotificationGroup[] => {
    return [
      {
        date: 'Today, 10 April',
        notifications: [
          {
            id: '1',
            title: 'Title text here',
            date: '2021-05-06T06:42:34Z',
            label: 'Lorem ipsum one line here.',
            icon: 'addClient',
            type: 'info',
          },
          {
            id: '2',
            title: 'Title text here',
            date: '2021-05-06T06:42:34Z',
            label: `Lorem ipsum first line here
            Lorem ipsum second line here`,
            icon: 'offers',
            type: 'warning',
          },
        ],
      },
      {
        date: 'Yesterday, 9 April',
        notifications: [
          {
            id: '3',
            title: 'Title text here',
            date: '2021-05-06T06:42:34Z',
            label: 'Lorem ipsum one line here.',
            icon: 'addClient',
            type: 'info',
          },
          {
            id: '4',
            title: 'Title text here',
            date: '2021-05-06T06:42:34Z',
            label: `Lorem ipsum first line here
            Lorem ipsum second line hereLorem ipsum first line here
            Lorem ipsum second line here`,
            icon: 'offers',
            type: 'warning',
          },
        ],
      },
      {
        date: 'Previously, 8 April',
        notifications: [
          {
            id: '5',
            title: 'Title text here',
            date: '2021-05-06T06:42:34Z',
            label: 'Lorem ipsum one line here.',
            icon: 'addClient',
            type: 'info',
          },
          {
            id: '6',
            title: 'Title text here',
            date: '2021-05-06T06:42:34Z',
            label: `Lorem ipsum first line here
            Lorem ipsum second line here`,
            icon: 'offers',
            type: 'warning',
          },
        ],
      },
    ];
  };
}
