<template>
  <header>
    <BaseContainer :class="$style.container">
      <div class="flex items-center flex-grow-1">
        <div>
          <BaseImage
            icon="kurtLogo"
            alt="Kurt Konig logo"
            class="hidden md:block h-[2.5rem] 2xl:h-[3.8rem] mb-1 2xl:mb-0"
          />
        </div>
        <EnvironmentIndicator />
      </div>
    </BaseContainer>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import EnvironmentIndicator from '@/shared/components/atoms/visual/EnvironmentIndicator/EnvironmentIndicator.vue';
import BaseImage from '@/shared/components/atoms/visual/BaseImage/BaseImage.vue';
import BaseContainer from '@/shared/components/atoms/visual/BaseContainer/BaseContainer.vue';

export default defineComponent({
  components: { BaseContainer, BaseImage, EnvironmentIndicator },
});
</script>

<style module>
.container {
  @apply flex items-center justify-between text-white py-1.5;
  @apply bg-g-1500;
}
</style>
