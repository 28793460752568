import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8fcadf2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "breadcrumbsContainer"
}
const _hoisted_2 = { class: "breadcrumbsText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.breadcrumbRoutes)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: route?.name,
            to: route?.path,
            class: "breadcrumbsLink"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h4", _hoisted_2, _toDisplayString(route?.name), 1),
              _createVNode(_component_BaseIcon, {
                icon: "next",
                class: "breadcrumbsIcon"
              })
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}