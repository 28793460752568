import { App } from 'vue';

import {
  BaseButton,
  BaseCheckbox,
  BaseText,
  BaseTextArea,
  BaseInput,
  BackButton,
  BaseIcon,
  CustomSelect,
  IncrementInput,
  InfoCard,
  InfoCardSection,
  InputGroup,
} from '@venginetech/ui-library';
import BaseContainer from '@/shared/components/atoms/visual/BaseContainer/BaseContainer.vue';
import BaseHeading from '@/shared/components/atoms/visual/BaseHeading/BaseHeading.vue';
import CompanyHeading from '@/shared/components/atoms/visual/CompanyHeading/CompanyHeading.vue';
import BaseError from '@/shared/components/atoms/visual/BaseError/BaseError.vue';
import SelectInput from '@/shared/components/atoms/inputs/SelectInput/SelectInput.vue';
import BaseImage from '@/shared/components/atoms/visual/BaseImage/BaseImage.vue';
import BaseNotification from '@/shared/components/atoms/visual/BaseNotification/BaseNotification.vue';
import BaseBreadcrumbs from '@/shared/components/molecules/BaseBreadcrumbs/BaseBreadcrumbs.vue';

export const globalComponents = {
  install: (app: App): App => {
    app.component('BaseButton', BaseButton);
    app.component('BaseContainer', BaseContainer);
    app.component('BaseHeading', BaseHeading);
    app.component('BaseInput', BaseInput);
    app.component('BaseTextArea', BaseTextArea);
    app.component('BaseError', BaseError);
    app.component('SelectInput', SelectInput);
    app.component('BaseCheckbox', BaseCheckbox);
    app.component('BaseText', BaseText);
    app.component('BaseIcon', BaseIcon);
    app.component('BaseImage', BaseImage);
    app.component('BaseNotification', BaseNotification);
    app.component('InputGroup', InputGroup);
    app.component('CompanyHeading', CompanyHeading);
    app.component('BaseBreadcrumbs', BaseBreadcrumbs);
    app.component('CustomSelect', CustomSelect);
    app.component('IncrementInput', IncrementInput);
    app.component('BackButton', BackButton);
    app.component('InfoCard', InfoCard);
    app.component('InfoCardSection', InfoCardSection);
    return app;
  },
};
