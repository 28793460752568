import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseNotification = _resolveComponent("BaseNotification")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.notificationsWrapper)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notif) => {
      return (_openBlock(), _createBlock(_component_BaseNotification, {
        key: notif.key,
        notification: notif
      }, null, 8, ["notification"]))
    }), 128))
  ], 2))
}