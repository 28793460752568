import { ApiUser } from '@/shared/interfaces/api/ApiUser';
import { ResultAsync, err, ok } from '@/shared/utils/result';
import { Users } from '@/pages/users/interfaces/users';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { Employees } from '@/shared/interfaces/services/employees';
import { ApiRole } from '@/shared/interfaces/api/ApiRole';
import { ApiAuthEmployee } from '@/shared/interfaces/api/ApiAuthEmployee';

export const authApiHeaders = {
  'Accept-Language': 'de', // This is different for core and auth api's
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export class UsersService implements Users {
  api: IApi;

  logger: Logger;

  employees: Employees;

  constructor(api: IApi, employees: Employees, logger: Logger) {
    this.api = api;
    this.employees = employees;
    this.logger = logger;
  }

  async getRoles(): ResultAsync<PaginatedResource<ApiRole>, ViolationErrorMap> {
    const response = await this.api.getPaginatedResource<ApiRole>(
      () => API_LINK.roles,
      { page: 1, size: 100 },
      true
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async getUsers(
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiUser>, ViolationErrorMap> {
    // TODO: think about caching the responses because employees are not changing often
    // TODO: refactor this when employee data is available from the users endpoint
    const responses = await Promise.all([
      this.api.getPaginatedResource<ApiAuthEmployee>((params) => {
        params.set('pagination', 'false');
        return API_LINK.users(params);
      }, options),
      this.employees.getEmployees(),
    ]);
    let users: ApiUser[] = [];
    if (responses[0].isOk()) {
      users = responses[0].value.items.map((employee): ApiUser => {
        const match = responses[1].find(
          (u) => u.workEmail === employee.identifier.toLocaleLowerCase()
        );
        return {
          id: employee.id.toString() ?? '',
          firstName: match?.firstName ?? '',
          lastName: match?.lastName ?? '',
          email: employee.identifier.toLocaleLowerCase() ?? '',
          roles: employee.roles.map((role) => role.id.toString()),
        };
      });
    }
    if (options.search) {
      users = users.filter(
        (user) =>
          options.search &&
          (user.firstName.toLowerCase().includes(options.search.toLowerCase()) ||
            user.lastName.toLowerCase().includes(options.search.toLowerCase()) ||
            user.email.toLowerCase().includes(options.search.toLowerCase()))
      );
    }
    if (options.user) {
      users = users.sort((a, b) =>
        options.user === 'asc'
          ? a.firstName.localeCompare(b.firstName)
          : b.firstName.localeCompare(a.firstName)
      );
    }
    return ok({ items: users, page: 1, pageCount: 1 });
  }

  async editUserRole(id: string, role: string): ResultAsync<ApiUser, ViolationErrorMap> {
    const response = await this.api.patch<ApiUser>(API_LINK.user(id), {
      body: JSON.stringify({ roles: [parseInt(role, 10)] }),
    });
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }
}
