export const levels = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 };
export type LevelTypes = keyof typeof levels;
export type SizeTypes = keyof typeof levels | undefined;

export const BaseHeadingStyles = {
  base: 'font-bold',
  sizes: {
    1: 'text-40 2xl:text-60',
    2: 'text-36 2xl:text-40',
    3: 'text-30 2xl:text-35',
    4: 'text-24 2xl:text-28',
    5: 'text-20 2xl:text-25',
  },
};
