<template>
  <EmptyHeader />
  <div v-bind="$attrs" class="flex flex-col flex-1">
    <div class="mx-auto mt-[25rem] flex flex-col justify-center">
      <BaseIcon
        icon="category_2"
        size="none"
        class="h-[12rem] lg:h-[16rem] 2xl:h-[24rem] mb-3 lg:mb-5 2xl:mb-8"
        viewBox="14 14 40 40"
      />
      <span
        class="text-20 lg:text-24 font-black whitespace-pre-wrap lg:whitespace-normal text-center max-w-[31.6rem] lg:max-w-none"
      >
        {{ t('maintenance_mode_title') }}
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BaseIcon } from '@venginetech/ui-library';
import { useTranslations } from '@/shared/composables/useTranslations';
import EmptyHeader from '@/shared/components/organisms/EmptyHeader/EmptyHeader.vue';

const { t } = useTranslations();
</script>
