import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center text-orange-100 text-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseIcon, {
      icon: "error",
      class: "h-3.5 w-3.5 mr-1.5 text-orange-100 flex-shrink-0"
    }),
    _renderSlot(_ctx.$slots, "default")
  ]))
}